import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import {
  SCHEMA_COLUMN_TYPE_KEYS,
  SchemaColumnTypes,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { SchemaColumnValidatorTypes } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/validator/schema.column.validator.types';
import { Checkbox, Col, Divider, Form, Input, Row, Select, Table } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import {
  CreateSchemaColumn,
  createSchemaColumnPermissionsRequest,
  createSchemaColumnRequest,
  DeleteSchemaColumn,
  deleteSchemaColumnPermissionsRequest,
  deleteSchemaColumnRequest,
  GetSchemaColumnById,
  getSchemaColumnByIdRequest,
  removeSchemaColumnOption,
  UpdateSchemaColumn,
  updateSchemaColumnProperties,
  updateSchemaColumnRequest,
} from '../../../../../core/schemasColumns/store/actions';
import { SchemaColumnReducer } from '../../../../../core/schemasColumns/store/reducer';
import FormModal, {
  FormReducerSubmitEvt,
} from '../../../../../shared/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '../../../../../shared/components/SharedForm/store/actions';
import { SharedFormReducer } from '../../../../../shared/components/SharedForm/store/reducer';
import { errorNotification } from '../../../../../shared/system/notifications/store/reducers';
import { getSchemaFromShortListBySchemaId } from '../../../../../shared/utilities/schemaHelpers';
import { titleCaseList } from '../../Schemas/FormFields';
import { formFields } from '../FormFields';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Alert, Button, Icon, Section } from '@blueprintjs/core';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import { getSchemaByIdRequest } from '../../../../../core/schemas/store/actions';
import ModuleEntityIcon from '../../../../../core/theme/ModuleEntityIcon';

type PathParams = {
  url: string;
  schemaId: string;
  schemaColumnId: string;
};

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;

type PropsType = RouteComponentProps<PathParams> & {
  match: any;
  formReducer: SharedFormReducer;
  schemaReducer: ISchemaReducer;
  schemaColumnReducer: SchemaColumnReducer;
  getColumn: (params: GetSchemaColumnById) => void;
  createColumn: (params: CreateSchemaColumn, cb?: any) => void;
  updateColumn: (params: UpdateSchemaColumn, cb?: any) => void;
  deleteColumn: (params: DeleteSchemaColumn, cb?: any) => void;
  initializeForm: any;
  removeOption: (params: any) => void;
  updateProperties: any;
  enablePermissions: any;
  disablePermissions: any;
  notifyError: any;
  getSchemaById: (payload: any, cb?: any) => void;
};

interface State {
  validatorsList: any[];
  deleteColumnModalIsVisible: boolean;
}

const { Option } = Select;

const uuid = uuidv4();

class SchemaColumnDetailView extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      validatorsList: [],
      deleteColumnModalIsVisible: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: Readonly<PropsType>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.schemaColumnReducer.selected !== this.props.schemaColumnReducer.selected) {
      this.renderValidatorOptions(this.props.schemaColumnReducer?.selected?.type);
    }

    if (prevProps.match.schemaColumnId !== this.props.match.schemaColumnId) {
      this.fetchData();
    }
  }

  fetchData() {
    const { match, getColumn } = this.props;

    const schemaId = match.params.schemaId;
    const schemaColumnId = match.params.schemaColumnId;

    getColumn({ schemaId, schemaColumnId });
  }

  renderValidatorOptions(type: any) {
    let validatorsList: any[];
    if (type === undefined) {
      validatorsList = [];
    } else {
      let options: any = [];

      Object.keys(SchemaColumnValidatorTypes).forEach((key) => {
        if (!!SchemaColumnValidatorTypes[key].columnTypes.find((t) => t === type)) {
          const option: any = SchemaColumnValidatorTypes[key].name;
          options = [...options, option];
        }
      });

      validatorsList = options;
    }

    this.setState({
      validatorsList,
    });
  }

  formRef = React.createRef<FormInstance>();

  resetTable = () => {
    this.formRef.current?.resetFields();
  };

  async handleFormSubmit(params: { event: 'UPDATE' }) {
    const { notifyError, updateColumn, match, getSchemaById } = this.props;

    const schemaId = match.params.schemaId;
    const schemaColumnId = match.params.schemaColumnId;

    try {
      if (!!this.formRef.current) {
        await this.formRef.current.validateFields();
        const formErrors = this.formRef.current ? this.formRef.current.getFieldsError() : [];
        const hasErrors = formErrors.filter(({ errors }) => errors.length).length > 0;

        if (hasErrors) {
          return notifyError({
            message: 'form has errors, fix them and resubmit',
            validation: null,
            data: null,
          });
        } else {
          const values = this.formRef.current.getFieldsValue();

          // Once Column is updated, fetch the schema back so we can update the shortlist.
          updateColumn({ schemaId, schemaColumnId, body: values }, () => {
            getSchemaById({ schemaId: schemaId });
          });

          setTimeout(() => {
            this.resetTable();
          }, 1000);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  deleteTheColumn() {
    const { deleteColumn, match, getSchemaById, history } = this.props;

    const schemaId = match.params.schemaId;
    const schemaColumnId = match.params.schemaColumnId;

    // Once Column is updated, fetch the schema back so we can update the shortlist. After that, move the user back to schema columns list.
    deleteColumn({ schemaId, schemaColumnId }, () => {
      this.closeModal();

      getSchemaById({ schemaId: schemaId }, () => {
        history.push(`/${SCHEMA_MODULE}/Schema/${schemaId}/#Columns`);
      });
    });
  }

  handleValidatorChange = (value: any) => {
    const { updateProperties } = this.props;

    const fieldValues = this.formRef.current?.getFieldsValue();

    updateProperties({
      validators: fieldValues.validators.map((elem: any) => ({ type: elem })),
    });
  };

  renderOptionsList = () => {
    return (
      <>
        <h3>Enum Options</h3>
        {this.renderOptionsFields()}
      </>
    );
  };

  addEnumOption() {
    const { schemaColumnReducer, updateProperties } = this.props;
    const { selected } = schemaColumnReducer;

    const existingOption = selected?.options?.map((elem) => ({
      id: elem.id,
      position: elem.position,
      label: elem.label,
      value: elem.value,
      description: elem.description,
    }));

    const newOption = {
      value: `OPTION_${existingOption ? existingOption.length + 1 : 1}`,
      label: '',
      description: null,
      id: undefined,
      position: existingOption ? existingOption.length + 1 : 1,
    };

    if (existingOption && existingOption.length > 0) {
      updateProperties({
        options: [...existingOption, newOption],
      });
    } else {
      updateProperties({
        options: [newOption],
      });
    }
  }

  removeEnumOption = (index: number) => {
    const { removeOption } = this.props;

    const fieldValues = this.formRef.current?.getFieldsValue();

    const itemToRemove = fieldValues.options[index];

    removeOption({ value: itemToRemove.value });
  };

  handleEnumOptionChange(e: any, index: number) {
    const { updateProperties } = this.props;

    const fieldValues = this.formRef.current?.getFieldsValue();

    updateProperties({
      options: fieldValues.options,
    });
  }

  enablePermissionsSchemaColumn = () => {
    const { enablePermissions, updateProperties, match } = this.props;

    const schemaId = match.params.schemaId;
    const schemaColumnId = match.params.schemaColumnId;

    enablePermissions({ schemaId, schemaColumnId }, (result: any) => {
      updateProperties({
        permissions: result,
      });
    });
  };

  disablePermissionsSchemaColumn = () => {
    const { disablePermissions, updateProperties, match } = this.props;

    const schemaId = match.params.schemaId;
    const schemaColumnId = match.params.schemaColumnId;

    disablePermissions({ schemaId, schemaColumnId });

    updateProperties({
      permissions: [],
    });
  };

  // ODN-2224 linked schema association for the LOOKUP column
  renderLinkedSchemaAssociationSelect() {
    const { match, schemaReducer, updateProperties } = this.props;
    const schemaId = match.params.schemaId;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);

    return (
      <Form.Item
        className="form-item"
        label="Linked Schema Association"
        name="linkedSchemaAssociationId"
        key="LinkedSchemaAssociationSelectFormItem"
        rules={[
          {
            required: true,
            message: 'Please select',
          },
        ]}
      >
        <Select
          key="LinkedSchemaAssociationSelect"
          placeholder="Select linked Schema Association"
          onChange={(e) => {
            updateProperties({ linkedSchemaAssociationId: e ?? null });
          }}
        >
          {schema?.associations.map((association: SchemaAssociationEntity) => (
            <Select.Option key={association.id} value={association.id}>
              {association.parentSchemaId !== schema.id ? (
                <div>
                  <span style={{ fontWeight: 600 }}>{association?.parentSchema?.entityName}</span> (
                  <span style={{ opacity: 0.8 }}>{association.label}</span>)
                </div>
              ) : (
                <div>
                  <span style={{ fontWeight: 600 }}>{association?.childSchema?.entityName}</span> (
                  <span style={{ opacity: 0.8 }}>{association.label}</span>)
                </div>
              )}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  // ODN-2224 linked schema types constraint for the LOOKUP column
  renderLinkedSchemaTypesConstraintSelect() {
    const { match, schemaReducer, schemaColumnReducer, updateProperties } = this.props;
    const { selected } = schemaColumnReducer;

    const schemaId = match.params.schemaId;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);

    const linkedSchemaAssociation = schema?.associations?.find(
      (a) => a.id === selected?.linkedSchemaAssociationId,
    );

    if (linkedSchemaAssociation && linkedSchemaAssociation?.schemaTypesConstraints?.length > 0) {
      return (
        <Form.Item
          className="form-item"
          label="Linked Schema Types Constraint"
          name="linkedSchemaTypesConstraintId"
          key={8}
          rules={[
            {
              required: true,
              message: 'Please select',
            },
          ]}
        >
          <Select
            placeholder="Select linked Schema Types Constraint"
            onChange={(e) => {
              updateProperties({ linkedSchemaTypesConstraintId: e ?? null });
            }}
          >
            {linkedSchemaAssociation?.schemaTypesConstraints?.map((constraint) => (
              <Select.Option key={constraint.id} value={constraint.id}>
                {constraint.parentSchemaType?.name ? constraint.parentSchemaType.name : 'ANY'}-
                {constraint.childSchemaType?.name ? constraint.childSchemaType.name : 'ANY'}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    }
  }

  renderSchemaColumnForm() {
    const { match, schemaReducer, schemaColumnReducer, updateProperties } = this.props;
    const { selected } = schemaColumnReducer;
    const schemaId = match.params.schemaId;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);

    this.formRef.current?.setFieldsValue({
      id: selected?.id,
      name: selected?.name,
      type: selected?.type,
      schemaTypeId: selected?.schemaTypeId,
      // ODN-2224 linked schema association for the LOOKUP column
      linkedSchemaAssociationId: selected?.linkedSchemaAssociationId,
      // ODN-2340 linked schema types constraint fot the LOOKUP column
      linkedSchemaTypesConstraintId: selected?.linkedSchemaTypesConstraintId,
      description: selected?.description,
      isStatic: selected?.isStatic,
      label: selected?.label,
      mapping: selected?.mapping,
      placeholder: selected?.placeholder,
      isHidden: selected?.isHidden,
      defaultValue: selected?.defaultValue,
      format: selected?.format || '',
      isVisibleInTables: selected?.isVisibleInTables,
      isDisabled: selected?.isDisabled,
      isTitleColumn: selected?.isTitleColumn,
      isStatusColumn: selected?.isStatusColumn,
      isBulkUpdatable: selected?.isBulkUpdatable, // ODN-1792
      position: selected?.position,
      columnPosition: selected?.columnPosition,
      validators: selected?.validators?.map((elem) => elem.type),
      valueCase: selected?.valueCase,
      options: selected?.options?.map((elem) => ({
        id: elem.id,
        position: elem.position,
        label: elem.label,
        value: elem.value,
        description: elem.description,
      })),
    });

    return (
      <Form
        layout={'vertical'}
        name="columnsForm"
        initialValues={this.formRef.current?.getFieldsValue()}
        ref={this.formRef}
        style={{ width: '100%', paddingBottom: 50 }}
      >
        <Row gutter={12}>
          <Col lg={{ span: 12, offset: 6 }} xs={{ span: 24, offset: 2 }}>
            <Form.Item
              className="form-item"
              name="id"
              label="id"
              key={0}
              style={{ display: 'none' }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="form-item"
              name="name"
              label="Name"
              key={1}
              rules={[
                {
                  required: true,
                  message: 'Please input value',
                },
              ]}
            >
              <Input
                placeholder="external mapping name"
                onChange={(e) => updateProperties({ name: e.target.value })}
              />
            </Form.Item>
            <Form.Item className="form-item" name="mapping" label="Mapping" key={2} rules={[]}>
              <Input
                placeholder="external system mapping"
                onChange={(e) => updateProperties({ mapping: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Schema Type"
              name="schemaTypeId"
              key={3}
              rules={[]}
            >
              <Select
                placeholder="Select type"
                allowClear
                onChange={(e) => {
                  updateProperties({ schemaTypeId: e ?? null });
                }}
              >
                {schema?.types.map((type: any) => (
                  <Select.Option key={type.id} value={type.id}>
                    {type.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Description"
              name="description"
              key={4}
              rules={[
                {
                  required: true,
                  message: 'Please input value',
                },
              ]}
            >
              <Input
                placeholder="Description"
                onChange={(e) => updateProperties({ description: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Label"
              name="label"
              key={5}
              rules={[
                {
                  required: true,
                  message: 'Please input value',
                },
              ]}
            >
              <Input
                placeholder="Label"
                onChange={(e) => updateProperties({ label: e.target.value })}
              />
            </Form.Item>
            <Form.Item className="form-item" label="Placeholder" name="placeholder" key={6}>
              <Input
                placeholder="Placeholder"
                onChange={(e) => updateProperties({ placeholder: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Type"
              name="type"
              key={7}
              rules={[
                {
                  required: true,
                  message: 'Please select',
                },
              ]}
            >
              <Select
                placeholder="Select type"
                onChange={(e) => {
                  updateProperties({ type: e });
                  this.renderValidatorOptions(e);
                }}
              >
                {SCHEMA_COLUMN_TYPE_KEYS.map((option: any) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {selected?.type === 'ENUM' || selected?.type === 'ENUM_MULTI_SELECT'
              ? this.renderOptionsList()
              : ''}
            {selected?.type === SchemaColumnTypes.LOOKUP ||
            selected?.type === SchemaColumnTypes.FILE_MULTIPLE ||
            selected?.type === SchemaColumnTypes.FILE_SINGLE
              ? this.renderLinkedSchemaAssociationSelect()
              : ''}
            {selected?.type === SchemaColumnTypes.LOOKUP ||
            selected?.type === SchemaColumnTypes.FILE_MULTIPLE ||
            selected?.type === SchemaColumnTypes.FILE_SINGLE
              ? this.renderLinkedSchemaTypesConstraintSelect()
              : ''}
            <Form.Item
              className="form-item"
              label="Validators"
              name="validators"
              key={9}
              rules={[
                {
                  required: false,
                  message: 'Please select',
                },
              ]}
            >
              {
                <Select
                  mode="multiple"
                  placeholder="Select validators"
                  onChange={this.handleValidatorChange}
                >
                  {this.state.validatorsList.map((elem: any) => (
                    <Select.Option key={elem} value={elem}>
                      {elem}
                    </Select.Option>
                  ))}
                </Select>
              }
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Default value"
              name="defaultValue"
              key={10}
              rules={[
                {
                  message: 'Please input value',
                },
              ]}
            >
              <Input
                placeholder="some value"
                onChange={(e) => updateProperties({ defaultValue: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Default format"
              name="format"
              key={11}
              rules={[
                {
                  required: false,
                  message: 'Please input value',
                },
              ]}
            >
              <Input
                placeholder="Default format"
                onChange={(e) => updateProperties({ format: e.target.value })}
              />
            </Form.Item>
            <Form.Item className="form-item" label="Value Case" key={12} name="valueCase">
              <Select onChange={(value) => updateProperties({ valueCase: value })} allowClear>
                {titleCaseList.map((it: any) => (
                  <Option value={it.value}>{it.label}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Position"
              name="position"
              key={13}
              rules={[
                {
                  required: false,
                  message: 'Please input value',
                },
              ]}
            >
              <Input
                placeholder="Position"
                type="number"
                onChange={(e) => updateProperties({ position: parseInt(e.target.value) })}
              />
            </Form.Item>
            <Form.Item
              className="form-item"
              label="Column position"
              name="columnPosition"
              key={14}
              rules={[
                {
                  required: false,
                  message: 'Please input value',
                },
              ]}
            >
              <Select
                placeholder="Select column position"
                allowClear
                onChange={(e: number) => updateProperties({ columnPosition: e })}
              >
                <Option value={1} key={15}>
                  1
                </Option>
                <Option value={2} key={16}>
                  2
                </Option>
              </Select>
            </Form.Item>
            <div className="columns-section-wrapper">
              <Row>
                <Col span={8}>
                  <Form.Item className="form-item" name="isTitleColumn" key={17}>
                    <Checkbox
                      checked={selected?.isTitleColumn}
                      onChange={(e) => updateProperties({ isTitleColumn: e.target.checked })}
                    >
                      Is Title Column
                    </Checkbox>
                  </Form.Item>
                  <Form.Item className="form-item" name="isDisabled" key={18}>
                    <Checkbox
                      checked={selected?.isDisabled}
                      onChange={(e) => updateProperties({ isDisabled: e.target.checked })}
                    >
                      Is Disabled
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-item" name="isVisibleInTables" key={19}>
                    <Checkbox
                      checked={selected?.isVisibleInTables}
                      onChange={(e) =>
                        updateProperties({
                          isVisibleInTables: e.target.checked,
                        })
                      }
                    >
                      Is Visible in Tables
                    </Checkbox>
                  </Form.Item>
                  <Form.Item className="form-item" name="isSearchable" key={20}>
                    <Checkbox
                      checked={selected?.isSearchable}
                      onChange={(e) => updateProperties({ isSearchable: e.target.checked })}
                    >
                      Is Searchable
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item className="form-item" name="isStatusColumn" key={21}>
                    <Checkbox
                      checked={selected?.isStatusColumn}
                      onChange={(e) => updateProperties({ isStatusColumn: e.target.checked })}
                    >
                      Is Status Column
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Divider />

            <div className={selected ? '' : 'columns-section-wrapper'}>
              {selected?.permissions && selected.permissions.length > 0 ? (
                <>
                  <Button
                    onClick={() => this.disablePermissionsSchemaColumn()}
                    loading={schemaColumnReducer.isRequesting}
                  >
                    Disable column access control
                  </Button>

                  <Table
                    size="small"
                    pagination={false}
                    dataSource={selected?.permissions as any}
                    columns={
                      [
                        { title: 'Name', dataIndex: 'name' },
                        { title: 'Description', dataIndex: 'description' },
                      ] as any
                    }
                    style={{ marginTop: '0.5rem' }}
                  />
                </>
              ) : (
                <Button
                  className="custom-column-button"
                  onClick={() => this.enablePermissionsSchemaColumn()}
                  loading={schemaColumnReducer.isRequesting}
                >
                  Enable column access control
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    );
  }

  renderOptionsFields() {
    return (
      <Form.List name="options">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field: any, index) => (
                <Row key={field.key} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={6}>
                    <Form.Item
                      name={[field.name, 'value']}
                      fieldKey={[field.fieldKey, 'value']}
                      rules={[{ required: true }]}
                      label="Value"
                    >
                      <Input
                        placeholder="Value"
                        onChange={(e) => this.handleEnumOptionChange(e, index)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={[field.name, 'label']}
                      fieldKey={[field.fieldKey, 'label']}
                      rules={[{ required: true }]}
                      label="Label"
                    >
                      <Input
                        placeholder="Label"
                        onChange={(e) => this.handleEnumOptionChange(e, index)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={[field.name, 'description']}
                      fieldKey={[field.fieldKey, 'description']}
                      rules={[{ required: false }]}
                      label="Description"
                    >
                      <Input
                        placeholder="Description"
                        onChange={(e) => this.handleEnumOptionChange(e, index)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name={[field.name, 'position']}
                      fieldKey={[field.fieldKey, 'position']}
                      rules={[{ required: true }]}
                      label="Position"
                    >
                      <Input
                        placeholder="Position"
                        onChange={(e) => this.handleEnumOptionChange(e, index)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1} style={{ marginTop: '37px', paddingLeft: '0' }}>
                    <DeleteOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        this.removeEnumOption(index);
                      }}
                    />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  onClick={() => {
                    this.addEnumOption();
                  }}
                  style={{ width: '100%' }}
                >
                  <PlusOutlined /> Add Option
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    );
  }

  showCreateForm() {
    const { initializeForm, schemaReducer, match } = this.props;

    const { schemaId } = match.params;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);

    let parsedFormFields = new Array();
    const hasTypes = schema && schema?.types?.length > 0;

    formFields.forEach((field: any) => {
      if (hasTypes && field.property === 'schemaTypeId') {
        field.options = schema?.types?.map((type) => ({
          label: type.name,
          value: type.id,
        }));

        parsedFormFields.push(field);
      } else if (!hasTypes && field.property === 'schemaTypeId') {
        // do not add the form field for selecting types
      } else {
        parsedFormFields.push(field);
      }
    });

    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Column',
      formFields: parsedFormFields,
      entityName: 'SchemaColumn',
    });
  }

  handleCreateColumnSubmit(params: FormReducerSubmitEvt) {
    const { createColumn, formReducer, match, schemaReducer, getSchemaById } = this.props;

    const { schemaId } = match.params;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);

    if (schema && params.data && !formReducer.isUpdateReq) {
      const body = {
        name: params.data.name,
        label: params.data.label,
        schemaTypeId: params.data.schemaTypeId,
        description: params.data.description,
        type: SchemaColumnTypes.TEXT,
        position: 0,
      };

      // Once Column is created, fetch the schema back so we can update the shortlist.
      createColumn({ schemaId: schema.id, body }, () => {
        getSchemaById({ schemaId: schema?.id });
      });
    }
  }
  closeModal = () => {
    this.setState({ deleteColumnModalIsVisible: false });
  };

  render() {
    const { schemaColumnReducer, schemaReducer, match } = this.props;
    const { selected } = schemaColumnReducer;

    const { schemaId } = match.params;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);

    return (
      <>
        <Alert
          intent="danger"
          canEscapeKeyCancel
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          isOpen={this.state.deleteColumnModalIsVisible}
          onCancel={this.closeModal}
          onClose={this.closeModal}
          onConfirm={() => this.deleteTheColumn()}
        >
          <p>Are you sure you want to delete this schema column? This action cannot be undone.</p>
        </Alert>
        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => this.handleCreateColumnSubmit(params)}
        />
        <Row>
          <Col span={24}>
            <Section
              title={
                <>
                  <Link to={`/${SCHEMA_MODULE}/Schema`}>
                    <span>Schemas</span>
                  </Link>
                  <Icon icon="caret-right" />
                  <Link to={`/${SCHEMA_MODULE}/Schema/${schema?.id}`}>
                    <span>{schema?.entityName || ''}</span>
                  </Link>
                  <Icon icon="caret-right" />
                  <Link to={`/${SCHEMA_MODULE}/Schema/${schema?.id}/#Columns`}>
                    <span>Columns</span>
                  </Link>
                  <Icon icon="caret-right" />
                  <span>
                    {selected?.name && !schemaColumnReducer.isRequesting ? selected?.name : '...'}
                  </span>
                </>
              }
              subtitle="Schema Manager"
              style={{ marginTop: 1 }}
              icon={
                <ModuleEntityIcon
                  moduleName="SchemaModule"
                  entityName="Schema"
                  overrideIcon="diagram-3-fill"
                  overrideIconColor="#1777ff"
                  iconContainerStyle={{ padding: '7px 10px' }}
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: 15 }}>
            <Section
              title="Column Properties"
              rightElement={
                <>
                  <Button
                    key="1"
                    intent="danger"
                    outlined
                    onClick={() => this.setState({ deleteColumnModalIsVisible: true })}
                  >
                    Delete
                  </Button>

                  <Button key={1} intent="primary" onClick={() => this.showCreateForm()} outlined>
                    New Column
                  </Button>
                  <Button
                    key="3"
                    intent="primary"
                    loading={schemaColumnReducer.isRequesting}
                    onClick={() => this.handleFormSubmit({ event: 'UPDATE' })}
                  >
                    Save
                  </Button>
                </>
              }
            >
              <Row style={{ padding: 20 }}>
                <Col span={24}>{this.renderSchemaColumnForm()}</Col>
              </Row>
            </Section>
          </Col>
        </Row>
      </>
    );
  }
}

const mapState = (state: any) => ({
  formReducer: state.formReducer,
  schemaReducer: state.schemaReducer,
  schemaColumnReducer: state.schemaColumnReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  updateProperties: (params: any) => dispatch(updateSchemaColumnProperties(params)),
  getColumn: (params: GetSchemaColumnById) => dispatch(getSchemaColumnByIdRequest(params)),
  createColumn: (params: CreateSchemaColumn, cb: any) =>
    dispatch(createSchemaColumnRequest(params, cb)),
  updateColumn: (params: UpdateSchemaColumn, cb: any) =>
    dispatch(updateSchemaColumnRequest(params, cb)),
  removeOption: (params: any) => dispatch(removeSchemaColumnOption(params)),
  deleteColumn: (params: DeleteSchemaColumn, cb: any) =>
    dispatch(deleteSchemaColumnRequest(params, cb)),
  enablePermissions: (params: any, cb: any) =>
    dispatch(createSchemaColumnPermissionsRequest(params, cb)),
  disablePermissions: (params: any, cb: any) =>
    dispatch(deleteSchemaColumnPermissionsRequest(params, cb)),
  notifyError: (params: any) => dispatch(errorNotification(params)),
  getSchemaById: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
});

export default withRouter(connect(mapState, mapDispatch)(SchemaColumnDetailView));
