import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';
import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { Card, Col, Dropdown, Menu, notification, Row } from 'antd';
import { Button } from '@blueprintjs/core';
import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ProjectModuleIntersectingPIA from '../../../../../../../../containers/ProjectModule/components/ProjectModuleIntersectingPIA';
import PTAListAndCreateCard from '../../../../../../../../containers/ProjectModule/containers/PTAListAndCreateCard';
import Flow from '../../../../../../../../core/flow/Flow';
import {
  IMapUpdateWorkItems,
  MapReducerUpdate,
  updateMapState,
  updateWorkItems,
} from '../../../../../../../../core/gis/store/actions';
import { MapReducer } from '../../../../../../../../core/gis/store/reducer';
import ActivityCenter from '../../../../../../../../core/records/components/ActivityCenter';
import AssociationDataTable from '../../../../../../../../core/recordsAssociations/components/AssociationDataTable';
import AssociationSimpleList from '../../../../../../../../core/recordsAssociations/components/AssociationSimpleList';
import RecordCard from '../../../../../../../../core/records/components/RecordCard';
import ModuleEntityIcon from '../../../../../../../../core/theme/ModuleEntityIcon';
import { FEATURE_NAMES } from '../../../../constants';
import { FeatureMapWidget } from '../../FeatureMapWidget';
import SplicingSummaryWidget from '../../SplicingSummaryWidget';
import SchemaActionFormLauncher from '../../../../../../../../v2/shared/components/OutcomeFormLauncher';
import SchemaActionPageHeader from '../../../../../../../../v2/shared/components/SchemaActions/SchemaActionPageHeader';

interface Props {
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
  updateWI: (payload: IMapUpdateWorkItems) => void;
  updateMap: (params: MapReducerUpdate) => {};
  mapReducer: MapReducer;
  closeDrawer: Function;
  showRecordInQuickView: Function;
}

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { FEATURE, OPENREACH_NERWORK_ADJUSTMENT } = SchemaModuleEntityTypeEnums;
type TQuickViewTabs = 'ACTIONS' | 'ACTIVITY' | 'FILES';

const FeatureRecordBody: FC<Props> = (props: Props) => {
  const { record, updateMap, updateWI, mapReducer, closeDrawer, showRecordInQuickView, schema } =
    props;
  const [activeTab, setActiveTab] = useState<TQuickViewTabs>('ACTIONS');
  const [flowVisible, setFlowVisible] = useState<boolean>(false);
  const [createdNetworkAdjustmentId, setCreatedNetworkAdjustmentId] = useState<string | undefined>(
    undefined,
  );
  const [showNANotification, setShowNANotification] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();

  // We disable RFC actions for features that are in build status 7 or 8.
  const isFeatureBS7or8 = (record: DbRecordEntityTransform | undefined) => {
    const BS = getProperty(record, 'BuildStatus');
    if (BS === '7' || BS === '8') {
      return true;
    } else {
      return false;
    }
  };

  // RFC - Enter mode to create Survey Structure on maps
  const enterRFCCreateSurveyStructure = () => {
    if (record) {
      updateMap({
        addEnabled: true,
        addPointFlow: false,
        creatingRFCFromIds: [record.id],
        isCreatingRFC: true,
        isRFCModalVisible: false,
        isRFCSelectFeatureTypeModalVisible: false,
        mapSidebarVisible: isMobile ? false : mapReducer.mapSidebarVisible,
      });

      updateWI({
        creatingBlockageFromId: undefined,
      });

      closeDrawer();
    }
  };

  // RFC - Enter mode to create Survey Route on maps
  const enterRFCCreateSurveyRoute = () => {
    if (record) {
      updateMap({
        addLineEnabled: true,
        creatingRFCFromIds: [record.id],
        isCreatingRFC: true,
        isRFCModalVisible: false,
        mapSidebarVisible: isMobile ? false : mapReducer.mapSidebarVisible,
      });

      updateWI({
        creatingBlockageFromId: undefined,
      });

      closeDrawer();
    }
  };

  // Enter mode to create Blockage on maps
  const createBlockage = () => {
    updateMap({
      createLinked: {
        fromType: record?.type?.toLowerCase() as FEATURE_NAMES,
        fromId: getProperty(record, 'ExternalRef'),
        toType: FEATURE_NAMES.BLOCKAGE,
      },
      drawEnabled: false,
      addEnabled: true,
    });

    updateWI({
      creatingBlockageFromId: record?.id,
    });

    closeDrawer();
  };

  // PIA Order - Utility Functions
  const toggleFlowVisibility = () => {
    setFlowVisible(!flowVisible);
  };
  const associationsForPIA = () => {
    if (record) {
      return [record].map((rec: any) => ({
        entity: rec.entity,
        recordId: rec.id,
        relationType: RelationTypeEnum.CHILD,
      }));
    } else {
      return [];
    }
  };

  // PIA Order - When Flow Step 1 finishes, set the created ORNA id to the state.
  const fetchCreatedNetworkAdjustmentId = (recordId: string) => {
    if (recordId) {
      setCreatedNetworkAdjustmentId(recordId);
    }
  };

  // PIA Order - Callback for Flow component - Step 2
  const PIAOrderCompleted = () => {
    setShowNANotification(true);
  };

  // PIA Order - When Flow Step 2 finished and there is ORNA id, show the notification
  useEffect(() => {
    if (createdNetworkAdjustmentId && showNANotification) {
      showNotification(createdNetworkAdjustmentId);
    }
  }, [showNANotification]);

  // PIA Order - Show Notification when the Flow ends
  const showNotification = (recordId: string) => {
    api['success']({
      key: recordId,
      message: 'Network Adjustment Created',
      duration: 5,
      onClose: () => {
        setCreatedNetworkAdjustmentId(undefined);
        setShowNANotification(false);
      },
      description: (
        <Link target="_blank" to={`/${PROJECT_MODULE}/${OPENREACH_NERWORK_ADJUSTMENT}/${recordId}`}>
          Go to Record
        </Link>
      ),
    });
  };

  // Find schemas with OUTCOME_FORM but watch for schema type constraints
  let schemasWithOutcomeForm: any[] =
    schema?.associations.filter(
      (assoc: SchemaAssociationEntity) =>
        assoc.childSchema?.types?.some((type: SchemaTypeEntity) => type.name === 'OUTCOME_FORM') &&
        assoc.schemaTypesConstraints?.find(
          (constraint: any) => constraint.parentSchemaType?.name === record?.type,
        ),
    ) || [];

  if (schemasWithOutcomeForm.length > 0) {
    schemasWithOutcomeForm = schemasWithOutcomeForm.map((sch: any) => sch.childSchema);
  }

  return (
    <>
      {contextHolder}
      {/* FEATURE RECORD */}
      <Row style={{ marginTop: 15 }}>
        <Col span={24}>
          {schema && record && (
            <SchemaActionPageHeader
              moduleName={schema.moduleName}
              entityName={schema.entityName}
              recordId={record.id}
              launcherType="SECTION"
              sectionTitle="Quick Actions"
            />
          )}
        </Col>

        <Col span={8}>
          <Button
            active={activeTab === 'ACTIONS'}
            intent={activeTab === 'ACTIONS' ? 'primary' : 'none'}
            outlined={activeTab !== 'ACTIONS'}
            large
            style={{
              width: '100%',
              borderRadius: '7px 0 0 7px',
              fontWeight: activeTab === 'ACTIONS' ? 600 : 400,
              borderRight: 'none',
            }}
            // type="primary"
            onClick={() => setActiveTab('ACTIONS')}
          >
            Actions
          </Button>
        </Col>
        <Col span={8}>
          <Button
            active={activeTab === 'ACTIVITY'}
            large
            intent={activeTab === 'ACTIVITY' ? 'primary' : 'none'}
            outlined={activeTab !== 'ACTIVITY'}
            style={{
              width: '100%',
              borderRight: 'none',
              borderRadius: 0,
              fontWeight: activeTab === 'ACTIVITY' ? 600 : 400,
            }}
            // type="primary"
            onClick={() => setActiveTab('ACTIVITY')}
          >
            Activity
          </Button>
        </Col>
        <Col span={8}>
          <Button
            large
            active={activeTab === 'FILES'}
            intent={activeTab === 'FILES' ? 'primary' : 'none'}
            outlined={activeTab !== 'FILES'}
            style={{
              width: '100%',
              borderRadius: '0 7px 7px 0',
              fontWeight: activeTab === 'FILES' ? 600 : 400,
            }}
            // type="primary"
            onClick={() => setActiveTab('FILES')}
          >
            Files
          </Button>
        </Col>
      </Row>

      {/* ACTIONS */}
      <Row style={{ display: activeTab === 'ACTIONS' ? 'block' : 'none', marginTop: 15 }}>
        {/* Closures in Build Status 8 should show Permit to Access Card */}
        {record?.type === 'CLOSURE' && getProperty(record, 'BuildStatus') === '8' ? (
          <Col span={24} style={{ marginTop: 17 }}>
            <PTAListAndCreateCard parentRecord={record!} />
          </Col>
        ) : (
          <></>
        )}

        {/* RFC List & Actions */}
        {!['SURVEY_ROUTE', 'SURVEY_STRUCTURE', 'BLOCKAGE'].includes(record?.type!) &&
        record?.entity !== `${PROJECT_MODULE}:${OPENREACH_NERWORK_ADJUSTMENT}` ? (
          <Col span={24} style={{ marginTop: 15 }}>
            <Card
              size="small"
              extra={
                <Dropdown
                  disabled={isFeatureBS7or8(record)}
                  trigger={['click']}
                  overlay={
                    <Menu>
                      {/* Create Survey Route */}
                      {record?.type &&
                      ['duct', 'cable', 'rope'].includes(record?.type?.toLowerCase()) ? (
                        <Menu.Item
                          key={'createSurveyRoute'}
                          onClick={() => enterRFCCreateSurveyRoute()}
                        >
                          Create Survey Route
                        </Menu.Item>
                      ) : (
                        <></>
                      )}

                      {/* Create Survey Structure */}
                      <Menu.Item
                        key={'createSurveyStructure'}
                        onClick={() => enterRFCCreateSurveyStructure()}
                      >
                        Create Survey Structure
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    icon={<CaretDownOutlined style={{ fontSize: '0.9em' }} />}
                  />
                </Dropdown>
              }
              title={
                <>
                  <ModuleEntityIcon
                    iconContainerStyle={{ padding: '5px 9px', fontSize: '1.1em' }}
                    overrideIcon="geo-alt-fill"
                    overrideIconColor="#1cc773"
                    moduleName=""
                    entityName=""
                  />
                  <span style={{ fontSize: '1.1em', marginTop: 2 }}>RFC</span>
                </>
              }
            >
              <Row>
                <Col span={24}>
                  <AssociationSimpleList
                    parentRecord={record}
                    associationEntityName={FEATURE}
                    associationModuleName={PROJECT_MODULE}
                    associationType="SURVEY_ROUTE"
                    recordColumns={['BuildStatus', 'type', 'ExternalRef']}
                    customRowAction={showRecordInQuickView}
                  />
                </Col>
                <Col span={24}>
                  <AssociationSimpleList
                    parentRecord={record}
                    associationEntityName={FEATURE}
                    associationModuleName={PROJECT_MODULE}
                    associationType="SURVEY_STRUCTURE"
                    recordColumns={['BuildStatus', 'type', 'ExternalRef']}
                    customRowAction={showRecordInQuickView}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        ) : (
          <></>
        )}

        {/* Associated Project */}
        <Col span={24} style={{ marginTop: 15 }}>
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            associatedRecordEntityName={SchemaModuleEntityTypeEnums.PROJECT}
            visibleProperties={[
              'Contractor',
              'PurchaseOrderNumber',
              'EstimatedStart',
              'EstimatedEnd',
            ]}
          />
        </Col>
        {/* Associated Features */}
        {['SURVEY_STRUCTURE', 'SURVEY_ROUTE', 'BLOCKAGE'].includes(record?.type!) ? (
          <Col span={24} style={{ marginTop: 15 }}>
            <Card
              size="small"
              title={
                <>
                  <ModuleEntityIcon
                    iconContainerStyle={{ padding: '5px 9px', fontSize: '1.1em' }}
                    moduleName={PROJECT_MODULE}
                    entityName={FEATURE}
                  />
                  <span style={{ fontSize: '1.1em', marginTop: 2 }}>Related Features</span>
                </>
              }
            >
              <AssociationSimpleList
                parentRecord={record}
                associationEntityName={FEATURE}
                associationModuleName={PROJECT_MODULE}
                customRowAction={showRecordInQuickView}
                recordColumns={['BuildStatus', 'type', 'ExternalRef']}
              />
            </Card>
          </Col>
        ) : (
          <></>
        )}

        {/* Blockages List & Actions */}
        {!['BLOCKAGE', 'SURVEY_STRUCTURE', 'SURVEY_ROUTE'].includes(record?.type!) ? (
          <Col span={24} style={{ marginTop: 15 }}>
            <Card
              size="small"
              extra={
                <Dropdown
                  disabled={!['ROPE', 'CABLE', 'CHAMBER', 'CLOSURE'].includes(record?.type!)}
                  trigger={['click']}
                  overlay={
                    <Menu>
                      {/* Create Survey Route */}
                      {record?.type &&
                      ['ROPE', 'CABLE', 'CHAMBER', 'CLOSURE'].includes(record?.type!) ? (
                        <Menu.Item key={'createBlockage'} onClick={() => createBlockage()}>
                          Create Blockage
                        </Menu.Item>
                      ) : (
                        <></>
                      )}
                    </Menu>
                  }
                >
                  <Button icon={<CaretDownOutlined style={{ fontSize: '0.9em' }} />} />
                </Dropdown>
              }
              title={
                <>
                  <ModuleEntityIcon
                    iconContainerStyle={{ padding: '5px 9px', fontSize: '1.1em' }}
                    overrideIcon="geo-alt-fill"
                    overrideIconColor="#1cc773"
                    moduleName=""
                    entityName=""
                  />
                  <span style={{ fontSize: '1.1em', marginTop: 2 }}>Blockage</span>
                </>
              }
            >
              <AssociationSimpleList
                parentRecord={record}
                associationEntityName={FEATURE}
                associationModuleName={PROJECT_MODULE}
                associationType="BLOCKAGE"
                recordColumns={['BuildStatus', 'type', 'ExternalRef']}
                customRowAction={showRecordInQuickView}
              />
            </Card>
          </Col>
        ) : (
          <></>
        )}

        {/* Blockages List & Actions */}
        {['BLOCKAGE'].includes(record?.type!) ? (
          <Col span={24} style={{ marginTop: 15 }}>
            <Card
              size="small"
              extra={
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu>
                      <Menu.Item key={'createPIAOrder'} onClick={() => setFlowVisible(true)}>
                        Create PIA Order
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button icon={<DownOutlined />} />
                </Dropdown>
              }
              title={
                <>
                  <ModuleEntityIcon
                    iconContainerStyle={{ padding: '5px 9px', fontSize: '1.1em' }}
                    moduleName={PROJECT_MODULE}
                    entityName={FEATURE}
                  />
                  <span style={{ fontSize: '1.1em', marginTop: 2 }}>Network Adjustments</span>
                </>
              }
            >
              <AssociationSimpleList
                parentRecord={record}
                associationEntityName={'OpenreachNetworkAdjustment'}
                associationModuleName={PROJECT_MODULE}
                recordColumns={['OrderState', 'recordNumber']}
                customRowAction={showRecordInQuickView}
              />
            </Card>
          </Col>
        ) : (
          <></>
        )}

        {/* Closures - Show Splicing Information */}
        {['CLOSURE'].includes(record?.type!) ? (
          <div style={{ marginTop: 15 }}>
            <SplicingSummaryWidget parentRecord={record} />
          </div>
        ) : (
          <></>
        )}

        {/* Outcome Forms Launcher */}
        {schemasWithOutcomeForm.length > 0 ? (
          <Col span={24} style={{ marginTop: 15 }}>
            <SchemaActionFormLauncher parentRecord={record!} parentSchema={schema!} />
          </Col>
        ) : (
          <></>
        )}

        <Col span={24}>
          <FeatureMapWidget feature={record} />
        </Col>
      </Row>

      {/* NOTES */}
      <Row
        style={{ display: activeTab === 'ACTIVITY' ? 'block' : 'none', marginTop: 20, padding: 10 }}
      >
        <Col span={24}>
          <ActivityCenter record={record!} schema={schema!} compact />
        </Col>
      </Row>

      {/* FILES */}
      {activeTab === 'FILES' ? (
        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            <AssociationDataTable
              thumbnailSize={12}
              title="Files"
              record={record!}
              showFileCategoryForType="DEFAULT"
              moduleName="SchemaModule"
              entityName="File"
              location="sidebar"
              collapsedByDefault
            />
          </Col>
          {/* If record type is CABLE show PIA Image Uploader  */}
          {record?.type === 'CABLE' && (
            <Col span={24}>
              <ProjectModuleIntersectingPIA record={record} />
            </Col>
          )}

          {/* If record type is DUCT (Type 2-SDuct) show PIA Image Uploader  */}
          {record?.type === 'DUCT' && getProperty(record, 'DuctType') === '2' && (
            <Col span={24}>
              <ProjectModuleIntersectingPIA record={record} />
            </Col>
          )}
        </Row>
      ) : (
        <></>
      )}
      <Flow
        launchInterface="NONE"
        flowVisible={flowVisible}
        launchInterfaceTitle=""
        toggleFlowVisibility={toggleFlowVisibility}
        flowSteps={[
          // 1. Create Network Adjustment
          {
            flowType: 'RECORD',
            title: 'Create Network Adjustment',
            moduleName: 'ProjectModule',
            entityName: 'OpenreachNetworkAdjustment',
            additionalAssociations: associationsForPIA(),
            runOnSubmit: fetchCreatedNetworkAdjustmentId,
          },
          // 2. Associate files to the newly created record
          {
            flowType: 'ASSOCIATION',
            title: 'Upload Files',
            moduleName: 'SchemaModule',
            entityName: 'File',
            recordType: 'DEFAULT',
            associatedFilesView: 'thumbnails',
            relateRecordFromStep: [1],
            runOnSubmit: PIAOrderCompleted,
            showFileCategoryForType: 'DEFAULT',
          },
        ]}
      />
    </>
  );
};

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateWI: (params: IMapUpdateWorkItems) => dispatch(updateWorkItems(params)),
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
});

export default connect(mapState, mapDispatch)(FeatureRecordBody);
