import { Alert, Button, Collapse, Divider, EntityTitle, Section, Spinner } from '@blueprintjs/core';
import { OrganizationUserEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/organization.user.entity';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { EditUser, editUserRequest } from '../../../../../core/identityUser/store/actions';
import SharedFormModal, {
  FormReducerSubmitEvt,
} from '../../../../../shared/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '../../../../../shared/components/SharedForm/store/actions';
import { httpDelete, httpGet, httpPatch } from '../../../../../shared/http/requests';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { formFields } from '../FormFields';
import { TerritoriesViewUsersDialog } from '../TerritoriesUserViewDialog';
import ManageTerritoryRegionsDialog from '../ManageTerritoryRegionsDialog';

interface Props {
  territory: any;
  initializeForm: (params: any) => void;
  onUpdate: () => void;
  onDelete: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const TerritoryDetails: FC<Props> = (props: Props) => {
  const { territory, onUpdate, initializeForm, alertMessage, onDelete } = props;
  const [isShowingFullDetails, setIsShowingFullDetails] = useState<boolean>(true);
  const [isDeleteTerritoryAlertVisible, setIsDeleteTerritoryAlertVisible] =
    useState<boolean>(false);
  const [copiedTextToClipboard, setCopiedTextToClipboard] = useState<boolean>(false);

  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<OrganizationUserEntity[]>([]);

  const [isLoadingRegions, setIsLoadingRegions] = useState<boolean>(false);
  const [regions, setRegions] = useState<any[]>([]);

  useEffect(() => {
    if (territory) {
      setUsers([]);
      setRegions([]);
      getAssociatedUsers(territory.id);
      getAssociatedRegions(territory.id);
    }
  }, [territory]);

  const getAssociatedUsers = async (territoryId: string) => {
    setIsLoadingUsers(true);
    try {
      const usersResponse = await httpGet(`IdentityModule/v2.0/territories/${territoryId}/users`);
      const users = usersResponse.data?.data;
      if (users) {
        setUsers(users);
      }
      setIsLoadingUsers(false);
    } catch (e: any) {
      alertMessage({ body: 'Could not fetch associated users. ' + e.message, type: 'error' });
      setIsLoadingUsers(false);
    }
  };

  const getAssociatedRegions = async (territoryId: string) => {
    setIsLoadingRegions(true);
    try {
      const regionsResponse = await httpGet(
        `IdentityModule/v2.0/territories/${territoryId}/regions`,
      );
      const regions = regionsResponse.data?.data;
      if (regions) {
        console.log('debug: territory -> regions', regions);
        setRegions(regions);
      }
      setIsLoadingRegions(false);
    } catch (e: any) {
      alertMessage({ body: 'Could not fetch associated regions. ' + e.message, type: 'error' });
      setIsLoadingRegions(false);
    }
  };


  const showEditTerritoryForm = () => {
    const tempEl: any = territory;
    let editForm: any[] = [];

    formFields.forEach((element: any) => {
      editForm.push({
        allowClear: element.allowClear,
        allowSearch: element.allowSearch,
        customValidation: element.customValidation,
        customValidationMessage: element.customValidationMessage,
        initialValue: tempEl[element.property],
        isDisabled: element.isDisabled,
        isHidden: element.isHidden,
        isRequired: element.isRequired,
        label: element.label,
        message: element.message,
        options: element.options,
        property: element.property,
        type: element.type,
        value: tempEl[element.property],
      });
    });

    initializeForm({
      formUUID: territory.id,
      title: 'Edit Territory',
      recordId: territory?.id,
      showModal: true,
      formFields: editForm,
      entityName: 'Territory',
      isUpdateReq: true,
      columns: 1,
    });
  };

  const editTerritory = async (body: any) => {
    try {
      return httpPatch(`IdentityModule/v2.0/territories/${territory.id}`, body).then(() => {
        alertMessage({ body: 'Territory updated', type: 'success' });
        onUpdate();
      });
    } catch {
      alertMessage({ body: 'Could not edit territory.', type: 'success' });
      throw new Error('Error editing territory.');
    }
  };

  const handleFormSubmit = (params: any) => {
    if (params.title === 'Edit Territory') {
      editTerritory(params.data);
    }
  };

  const deleteTerritory = async () => {
    try {
      await httpDelete(`IdentityModule/v2.0/territories/${territory.id}`);
      alertMessage({ body: 'Territory deleted', type: 'success' });
      onDelete();
    } catch (e: any) {
      alertMessage({ body: e.message, type: 'error' });
    }
  };

  const copyTextToClipboard = (text: any) => {
    navigator.clipboard.writeText(text);
    setCopiedTextToClipboard(true);
    setTimeout(() => {
      setCopiedTextToClipboard(false);
    }, 1500);
  };

  return (
    <>
      <Row style={{ padding: 15 }}>
        <SharedFormModal
          formUUID={territory.id}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />

        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h3 style={{ marginTop: 0 }}>{territory.name}</h3>
              <span>{territory.description}</span>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginTop: 20, marginBottom: 0 }}>
          <Row gutter={8}>
            <Col span={12}>
              <Button
                icon="trash"
                intent="danger"
                text="Delete Territory"
                disabled
                fill
                onClick={() => setIsDeleteTerritoryAlertVisible(true)}
              />
            </Col>
            <Col span={12}>
              <Button
                text="Edit Territory"
                fill
                icon="annotation"
                onClick={(e: any) => {
                  showEditTerritoryForm();
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Users */}
        <Col span={24} className="detailViewSection">
          <Section
            title={`Users ${users.length ? `(${users.length})` : ''}`}
            rightElement={
              !isLoadingUsers && users.length > 0 ? (
                <TerritoriesViewUsersDialog territory={territory} users={users} />
              ) : (
                <></>
              )
            }
          >
            <Row>
              {/* Users list */}
              {users.slice(0, 3)?.map((user: any) => (
                <Col span={24} key={user.id}>
                  <EntityTitle key={user.id} icon="person" title={user.name} />
                </Col>
              ))}
              {/* Show how many more Users  */}
              {users.length! > 3 && (
                <Col span={24} style={{ marginTop: 8 }}>
                  <span>{`+ ${users?.length - 3} more`}</span>
                </Col>
              )}
              {!users.length && (
                <Col span={24}>
                  <span>
                    {isLoadingUsers ? (
                      <Spinner size={15} style={{ justifyContent: 'start' }} />
                    ) : (
                      'None'
                    )}
                  </span>
                </Col>
              )}
            </Row>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Regions */}
        <Col span={24} className="detailViewSection">
          <Section
            title={`Regions ${regions.length ? `(${regions.length})` : ''}`}
            rightElement={
              !isLoadingRegions ? (
                <>
                <ManageTerritoryRegionsDialog
                  territory={territory}
                  regions={regions}
                  onUpdate={onUpdate}
                />
                </>
              ) : (
                <></>
              )
            }
          >
            <Row>
              {/* Regions list */}
              {regions.slice(0, 3)?.map((territory: any) => (
                <Col span={24} key={territory.id}>
                  <EntityTitle key={territory.id} icon="area-of-interest" title={territory.name} />
                </Col>
              ))}
              {/* Show how many more Territories  */}
              {regions.length! > 3 && (
                <Col span={24} style={{ marginTop: 8 }}>
                  <span>{`+ ${regions?.length - 3} more`}</span>
                </Col>
              )}
              {!regions.length && (
                <Col span={24}>
                  <span>
                    {isLoadingRegions ? (
                      <Spinner size={15} style={{ justifyContent: 'start' }} />
                    ) : (
                      'None'
                    )}
                  </span>
                </Col>
              )}
            </Row>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Territory ID */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Territory Id"
            rightElement={
              <Button
                minimal
                small
                rightIcon={copiedTextToClipboard ? 'tick' : null}
                text={copiedTextToClipboard ? 'Copied' : 'Copy'}
                intent={copiedTextToClipboard ? 'success' : 'primary'}
                onClick={() => copyTextToClipboard(territory.id)}
              />
            }
          >
            <span>{territory.id}</span>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Full Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Full Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingFullDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingFullDetails(!isShowingFullDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingFullDetails}>
                  <Row>
                    {/* Updated At */}
                    <Col span={24} style={{ marginTop: 5 }}>
                      <EntityTitle
                        title="Updated At"
                        subtitle={
                          <span>
                            {dayjs(territory.updatedAt).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>

                    {/* Created At */}
                    <Col span={24} style={{ marginTop: 10, marginBottom: 20 }}>
                      <EntityTitle
                        title="Created At"
                        subtitle={
                          <span>
                            {dayjs(territory.createdAt).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>
      </Row>

      {/* Delete Group Alert */}
      <Alert
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={isDeleteTerritoryAlertVisible}
        onCancel={() => setIsDeleteTerritoryAlertVisible(false)}
        onClose={() => setIsDeleteTerritoryAlertVisible(false)}
        onConfirm={deleteTerritory}
      >
        <p>Are you sure you want to delete this territory? This action cannot be undone.</p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  editUser: (params: EditUser, cb: any) => dispatch(editUserRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(TerritoryDetails);
