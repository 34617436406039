import { CaretDownFilled } from '@ant-design/icons';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Descriptions, Dropdown, MenuProps, Skeleton, Space } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import SchemaActionCreateUpdateModal from '../../SchemaActionCreateUpdateModal';
import { connect } from 'react-redux';
import { toggleSchemaActionCreateEditDrawer } from '../../../../../../core/userInterface/store/actions';
import { getPipelinesByModuleAndEntity } from '../../../../../../core/pipelines/store/actions';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { Button, Section } from '@blueprintjs/core';
import { renderBooleanValue } from '../../../../../../v2/shared/helpers/UIHelpers';

interface Props {
  schema: SchemaEntity | undefined;
  schemaAction: SchemaActionEntity | undefined;
  refreshAction: (id: string) => void;
  toggleDrawer: () => void;
  getPipeline: (payload: any, cb?: any) => void;
}

const SchemaActionLeftPanel: FC<Props> = (props) => {
  const { schema, schemaAction, refreshAction, toggleDrawer, getPipeline } = props;
  const [pipeline, setPipeline] = useState<PipelineEntity | undefined>(undefined);

  const excludedProperties = ['definition'];

  useEffect(() => {
    if (schema) {
      getPipeline({ schema }, (data: any) => {
        if (data.length > 0) {
          setPipeline(data[0]);
        }
      });
    }
  }, [schema]);

  const renderLabel = (key: any, value: any) => {
    if (
      [
        'isCreate',
        'isUpdate',
        'isStepFlow',
        'isBulkUpdate',
        'userAction',
        'defaultForm',
        'isUpdateFlow',
        'isCreateFlow',
      ].includes(key)
    ) {
      return renderBooleanValue(value ? 'true' : 'false');
    } else if (['createdAt', 'updatedAt'].includes(key)) {
      console.log('debug: date value', value);
      return dayjs(value).format('DD/MM/YYYY HH:mm:ss');
    } else if (key === 'permissions') {
      return value.map((permission: any) => permission.name).join(', ');
    } else {
      console.log('debug: value', value);
      return value;
    }
  };

  const renderSchemaActionDetails = (action: SchemaActionEntity) => {
    const actionObjectKeys: string[] = Object.keys(action);

    // remove excluded properties
    excludedProperties.forEach((property) => {
      const index = actionObjectKeys.indexOf(property);
      if (index > -1) {
        actionObjectKeys.splice(index, 1);
      }
    });

    // sort object keys so that the 'name' is on top
    actionObjectKeys.sort((a: any, b: any) => {
      if (['name'].includes(a)) {
        return -1;
      } else if (['description'].includes(a)) {
        return 0;
      } else {
        return 1;
      }
    });

    return (
      <Descriptions
        layout="horizontal"
        bordered
        column={1}
        size="small"
        labelStyle={{ fontSize: '0.9em', fontWeight: 500 }}
        contentStyle={{ fontSize: '0.9em' }}
      >
        {actionObjectKeys.map((key: string) => {
          return (
            <Descriptions.Item label={key}>
              {renderLabel(key, action[key as keyof SchemaActionEntity])}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    );
  };

  const enableAccessControl = () => {};

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Edit Action',
      onClick: toggleDrawer,
    },
    {
      key: '2',
      label: 'Enable Access Control',
      onClick: enableAccessControl,
    },
  ];

  return (
    <Section
      title="Schema Action"
      style={{ height: '90vh' }}
      rightElement={
        <Dropdown menu={{ items }} trigger={['click']}>
          <Button style={{ padding: '0 8px' }}>
            <Space>
              <CaretDownFilled />
            </Space>
          </Button>
        </Dropdown>
      }
    >
      <div style={{ height: '92%', overflowY: 'auto' }}>
        <Skeleton loading={!schema || !schemaAction}>
          {schemaAction && renderSchemaActionDetails(schemaAction)}
        </Skeleton>
        <SchemaActionCreateUpdateModal
          isCreate={false}
          actionToUpdate={schemaAction}
          schemaId={schema?.id}
          onSuccess={refreshAction}
        />
      </div>
    </Section>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  toggleDrawer: () => dispatch(toggleSchemaActionCreateEditDrawer()),
  getPipeline: (params: { schema: SchemaEntity }, cb: any) =>
    dispatch(getPipelinesByModuleAndEntity(params, cb)),
});

export default connect(mapState, mapDispatch)(SchemaActionLeftPanel);
