import { useState } from "react"
import dayjs, { Dayjs } from "dayjs";

import { DbRecordEntityTransform } from "@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform";
import { getProperty } from "@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers";
import { UpsertSlotProperties, upsertSlot } from "./upsertSlot";
import { useCalendarUtilization } from "../useCalendarUtilization";

export type SlotInfo = {
  date: string;
  AMScheduled: number;
  AMTotal: number;
  PMScheduled: number;
  PMTotal: number;
  Utilization: number;
  MgmtComment: string;
}

// Helpers
const getValidRange = (scheduleConfig: DbRecordEntityTransform) => {
  const availableFrom = getProperty(scheduleConfig, 'AvailableFrom');
  const availableTo = getProperty(scheduleConfig, 'AvailableTo') ?? dayjs().add(3, 'years').format('YYYY-MM-DD');
  const validRange: [Dayjs, Dayjs] = [dayjs(availableFrom), dayjs(availableTo)];

  return validRange;
}

const formatRange = (start: Dayjs, end: Dayjs) => (
  <div style={{ letterSpacing: '-1px' }}>
    <span>{start.format('DD MMMM YYYY')}</span>
    <span style={{ margin: '0 8px' }}> - </span>
    <span>{end.format('DD MMMM YYYY')}</span>
  </div>
);

export const useCalendarState = (scheduleConfig: DbRecordEntityTransform) => {
  const today = dayjs();
  const [selectedDate, setSelectedDate] = useState(today.format('YYYY-MM-DD'));
  const [slotToEdit, setSlotToEdit] = useState<SlotInfo | undefined>();
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    loading: isLoading,
    data: utilizationInfo,
    refetch: refetchUtilizationInfo,
  } = useCalendarUtilization({ record: scheduleConfig, selectedDate });

  // Update Slot Modal
  const startSlotEdit = (slotInfo: SlotInfo) => setSlotToEdit(slotInfo);
  const closeModal = () => setSlotToEdit(undefined);

  const updateSlot = async (values: UpsertSlotProperties) => {
    setIsUpdating(true);

    await upsertSlot(scheduleConfig.id, selectedDate, values);
    refetchUtilizationInfo();
    setIsUpdating(false);
    closeModal();
  };

  // Date ranges
  const currentRange = {
    start: dayjs(selectedDate).startOf('month'),
    end: dayjs(selectedDate).endOf('month'),
  };

  const validRange = getValidRange(scheduleConfig);

  const onChange = (date: Dayjs) => {
    setSelectedDate(date.format('YYYY-MM-DD'));
  };

  // Calendar Navigation
  const previous = () => {
    setSelectedDate(value => {
      return dayjs(value).add(-1, 'month').format('YYYY-MM-DD');
    })
  };

  const next = () => {
    setSelectedDate(value => {
      return dayjs(value).add(1, 'month').format('YYYY-MM-DD');
    });
  }

  return {
    state: {
      selectedDate,
      isLoading,
      utilizationInfo,
      currentRange: formatRange(currentRange.start, currentRange.end),
      slotToEdit,
      isUpdating
    },
    props: { validRange, value: dayjs(selectedDate), onChange },
    service: { previous, next, startSlotEdit, closeModal, updateSlot }
  }
}
