import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, List, Popover, Row, Select, Tag } from 'antd';
import { FC, useEffect, useState } from 'react';
import './styles.scss';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getRecordAuditLogs, IGetRecordAuditLogs } from '../../../records/auditLogs/store/actions';
import { connect } from 'react-redux';
import { usePrevious } from '../../../../shared/utilities/reactHelpers';
import { checkIfActivityIsAnEmail } from '../../../records/components/ActivityCenter/helpers';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import dayjs from 'dayjs';
import { Section, Icon, Button } from '@blueprintjs/core';
import Typography from '../../../../v2/shared/core/Typography';

interface Props {
  record: DbRecordEntityTransform;
  getAuditLogs: (
    params: IGetRecordAuditLogs,
    cb?: (params: { recordId: string; results: any[] }) => void,
  ) => void;
  schema: SchemaEntity;
}

const CommunicationsHistory: FC<Props> = (props: Props) => {
  const { record, getAuditLogs, schema } = props;
  const [recordId, setRecordId] = useState<string | undefined>(undefined);
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [filter, setFilter] = useState<'ALL' | 'EMAIL' | 'CALL' | 'SMS'>('ALL');
  const [loadingActivities, setLoadingActivities] = useState<boolean>(false);
  const [activityList, setActivityList] = useState<any[]>([]);

  const previousRecordId = usePrevious(recordId);

  useEffect(() => {
    if (record && record?.id !== recordId) {
      setRecordId(record?.id);
    }
  }, [record]);

  // Component is mounted, but the record is updated
  useEffect(() => {
    if (
      recordId &&
      schema &&
      previousRecordId &&
      recordId !== previousRecordId &&
      !loadingActivities
    ) {
      getActivity(recordId, schema);
    }
  }, [recordId]);

  // Fetch Activity audit logs when schema is available
  useEffect(() => {
    if (record && schema && !loadingActivities) {
      getActivity(record?.id, schema);
    }
  }, [schema]);

  // Fetch activities from the UserActivity API
  const getActivity = async (parentRecordId: string, schema: SchemaEntity) => {
    if (parentRecordId) {
      setLoadingActivities(true);
      getAuditLogs(
        {
          schema: schema,
          recordId: parentRecordId,
          entities: ['Note'],
          sort: { createdAt: 'DESC' },
        },
        (res: any) => {
          setLoadingActivities(false);

          if (res?.results?.length! > 0) {
            setActivityList([]);

            let activities = Object.assign(res?.results);
            activities = activities.sort((a: any, b: any) => {
              return dayjs(a.createdAt).diff(dayjs(b.createdAt));
            });

            activities.forEach((activity: any) => {
              // Only add activities that are emails, calls or sms
              if (
                isActivityCall(activity) ||
                isActivitySMS(activity) ||
                checkIfActivityIsAnEmail(activity)
              ) {
                setActivityList((prevActivityList) => [...prevActivityList, activity]);
              }
            });
          }
        },
      );
    }
  };

  const isActivitySMS = (activity: any) => {
    const SMSEntity = 'NotificationModule:SmsMessage';
    return (
      activity?.revision?.childEntity === SMSEntity ||
      activity?.revision?.parentEntity === SMSEntity
    );
  };

  const isActivityCall = (activity: any) => {
    const callEntity = 'NotificationModule:Call';
    return (
      activity?.revision?.childEntity === callEntity ||
      activity?.revision?.parentEntity === callEntity
    );
  };

  const filterByActivityType = (data: any[]) => {
    if (filter === 'ALL') {
      return data;
    } else {
      return data.filter((activity: any) => activity.type === filter);
    }
  };

  const getDateTimeForActivity = (activity: any) => {
    if (checkIfActivityIsAnEmail(activity)) {
      let user = activity.userName;
      let title = 'No Title';

      const recordWithTitle = activity.associations.find(
        (assoc: any) => assoc.title.indexOf('SENDGRID') > -1,
      );

      if (recordWithTitle) {
        title = recordWithTitle.title?.substring(0, 28) + '...';
      }

      if (user) {
        return (
          <Typography size="small">
            by {user}
            <br />
            {DateTime.fromISO(activity.createdAt as any).toFormat('d/M/yyyy h:mm a ZZZZ')}
            <br />
            {title}
          </Typography>
        );
      }
    } else {
      return DateTime.fromISO(activity.createdAt as any).toFormat('d/M/yyyy h:mm a ZZZZ');
    }
  };

  const getDataForList = () => {
    let data: any[] = [];

    const getRecordId = (entity: string, activity: any) => {
      if (activity?.revision?.parentEntity === entity) {
        return activity?.revision?.parentRecordId;
      } else if (activity?.revision?.childEntity === entity) {
        return activity?.revision?.childRecordId;
      }
    };

    data = activityList.map((activity: any) => {
      if (isActivitySMS(activity)) {
        return {
          title: 'SMS Sent',
          icon: 'bi bi-chat-dots',
          description: getDateTimeForActivity(activity),
          link: `/NotificationModule/SmsMessage/${getRecordId(
            'NotificationModule:SmsMessage',
            activity,
          )}`,
          type: 'SMS',
        };
      } else if (isActivityCall(activity)) {
        return {
          title: 'Outgoing Call',
          icon: 'bi bi-telephone-outbound',
          description: getDateTimeForActivity(activity),
          link: `/NotificationModule/Call/${getRecordId('NotificationModule:Call', activity)}`,
          type: 'CALL',
        };
      } else if (checkIfActivityIsAnEmail(activity)) {
        return {
          title: 'Email Sent',
          icon: 'bi bi-envelope',
          description: getDateTimeForActivity(activity),
          link: `/SchemaModule/File/${getRecordId('SchemaModule:File', activity)}`,
          type: 'EMAIL',
        };
      }
    });

    data = filterByActivityType(data);
    return data.reverse();
  };

  const handleFilterOpen = (newState: boolean) => {
    setFilterVisible(newState);
  };

  return (
    <Section
      icon={<Icon icon="chat" />}
      rightElement={
        <>
          {filter !== 'ALL' && (
            <Tag closable onClose={() => setFilter('ALL')}>
              {filter}
            </Tag>
          )}
          <Popover
            content={
              <Row>
                <Col span={24}>
                  <Select style={{ width: '100%' }} value={filter} onChange={setFilter}>
                    <Select.Option key="ALL" label="All" value="ALL">
                      All
                    </Select.Option>
                    <Select.Option key="EMAIL" label="Email" value="EMAIL">
                      Email
                    </Select.Option>
                    <Select.Option key="CALL" label="Call" value="CALL">
                      Call
                    </Select.Option>
                    <Select.Option key="SMS" label="SMS" value="SMS">
                      SMS
                    </Select.Option>
                  </Select>
                </Col>
              </Row>
            }
            title={
              <Row justify="space-between">
                <Col>Filter By</Col>
                <Col>
                  <a onClick={() => setFilterVisible(false)}>Close</a>
                </Col>
              </Row>
            }
            trigger="click"
            open={filterVisible}
            onOpenChange={handleFilterOpen}
          >
            <Button
              minimal
              icon={<Icon icon="filter" />}
              onClick={() => setFilterVisible(!filterVisible)}
              small
            />
          </Popover>
        </>
      }
      title={
        <>
          {/* <i className="bi bi-clock-history" /> */}
          <span>Communication</span>
        </>
      }
      compact
    >
      <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Col span={24}>
          <List
            style={{ opacity: loadingActivities ? 0.2 : 1 }}
            className="communicationsHistoryList"
            itemLayout="horizontal"
            dataSource={getDataForList()}
            renderItem={(item: any, index: number) => (
              <List.Item key={item.description}>
                <List.Item.Meta
                  key={`meta${item.description}`}
                  avatar={<i className={`${item.icon}`} />}
                  title={
                    <Link to={item.link} target="_blank">
                      <Typography stronger>{item.title}</Typography>
                    </Link>
                  }
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Section>
  );
};

const mapDispatch = (dispatch: any) => ({
  getAuditLogs: (
    params: IGetRecordAuditLogs,
    cb?: (params: { recordId: string; results: any[] }) => void,
  ) => dispatch(getRecordAuditLogs(params, cb)),
});

const mapState = (state: any) => ({});

export default connect(mapState, mapDispatch)(CommunicationsHistory);
