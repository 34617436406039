import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Calendar, Col, Layout, Popover, Row, Spin } from 'antd';
import { Dayjs } from 'dayjs';
import { isMobile } from 'react-device-detect';
import { CalendarHeader } from './CalendarHeader';

import { DateAvailability } from './DateAvailability';
import { EditSlotInfo } from './EditSlotInfo';
import { useCalendarState } from './hooks/useCalendarState';
import { getScheduleConfigSummary } from './hooks/useCalendarUtilization';
import { FileTextOutlined } from '@ant-design/icons';

import './styles.scss';

type ServiceAppointmentConfigCalendarProps = {
  record: DbRecordEntityTransform;
};

export const ServiceAppointmentConfigCalendar = ({
  record,
}: ServiceAppointmentConfigCalendarProps) => {
  const {
    state: calendarState,
    props: calendarProps,
    service: calendarService,
  } = useCalendarState(record);

  const { isLoading, utilizationInfo } = calendarState;

  const renderDay = (date: Dayjs) => {
    const currentUtilization = utilizationInfo.find((u) => u.Date === date.format('YYYY-MM-DD'));
    const slotToDisplay = currentUtilization?.Slot;

    if (!slotToDisplay && !currentUtilization) return null;

    const AMScheduled = currentUtilization.AMCount;
    const PMScheduled = currentUtilization.PMCount;
    const AMTotal = getProperty(slotToDisplay, 'AMQuantity') ?? currentUtilization.MaxAMCount;
    const PMTotal = getProperty(slotToDisplay, 'PMQuantity') ?? currentUtilization.MaxPMCount;
    const Utilization = getProperty(slotToDisplay, 'Utilization') ?? currentUtilization.Utilization;
    const MgmtComment = getProperty(slotToDisplay, 'MgmtComment');

    const isPopoverOpen = calendarState.slotToEdit?.date === date.format('YYYY-MM-DD');

    return (
      <Popover
        className="clickableDayCell"
        content={
          <Spin spinning={calendarState.isUpdating}>
            <EditSlotInfo
              slotInfo={calendarState.slotToEdit!}
              onSubmit={calendarService.updateSlot}
              onCancel={calendarService.closeModal}
            />
          </Spin>
        }
        open={isPopoverOpen}
        trigger="click"
      >
        <div>
          {!!MgmtComment && (
            <span
              title={MgmtComment}
              style={{
                position: 'absolute',
                display: 'inline-block',
                top: -5,
                left: 10,
                padding: 10,
                zIndex: 2,
                alignContent: 'center',
              }}
            >
              <FileTextOutlined />
            </span>
          )}
          <DateAvailability
            slotInfo={{
              date: date.format('YYYY-MM-DD'),
              AMScheduled,
              AMTotal,
              PMScheduled,
              PMTotal,
              Utilization,
              MgmtComment,
            }}
            calendarService={calendarService}
          />
        </div>
      </Popover>
    );
  };

  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      <CalendarHeader
        calendarState={calendarState}
        calendarService={calendarService}
        scheduleConfigSummary={getScheduleConfigSummary(utilizationInfo)}
      />

      <Row style={{ borderRadius: 10, padding: '10px 0 20px 0' }} align="middle">
        {/* Date Indicator */}
        <Col xs={24} md={24} style={{ textAlign: isMobile ? 'center' : 'left' }}>
          <Spin spinning={isLoading}>
            <Calendar className="scheduleCalendar" {...calendarProps} cellRender={renderDay} />
          </Spin>
        </Col>
      </Row>
    </Layout>
  );
};
