import {
  Alert,
  Button,
  Menu,
  MenuItem,
  Popover,
  Section,
  SectionCard,
  Tag,
} from '@blueprintjs/core';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { Col, Descriptions, Row } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PipelineStagesList from '../../Stages/ListView';
import { SharedFormReducer } from '../../../../../../shared/components/SharedForm/store/reducer';
import { initializeSharedForm } from '../../../../../../shared/components/SharedForm/store/actions';
import { pipelineFormFields } from '../../FormFields';
import FormModal, {
  FormReducerSubmitEvt,
} from '../../../../../../shared/components/SharedForm/SharedFormModal';
import { httpDelete, httpPost, httpPut } from '../../../../../../shared/http/requests';
import { pipelineStageFormFields } from '../../Stages/FormFields';
import { PipelineStageEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';

interface Props {
  last: boolean;
  pipeline: PipelineEntity;
  initializeForm: any;
  schema: any;
  disableDelete: boolean;
  onUpdate: () => void;
}

const PipelineSection: React.FC<Props> = (props: Props) => {
  const { pipeline, last, initializeForm, schema, disableDelete, onUpdate } = props;
  const [isDeletingPipeline, setIsDeletingPipeline] = useState<boolean>(false);
  const [pipelineToDelete, setPipelineToDelete] = useState<PipelineEntity | undefined>(undefined);
  const [confirmDeleteRecordDialogVisible, setConfirmDeleteRecordDialogVisible] =
    useState<boolean>(false);
  const [isUpdatingPipeline, setIsUpdatingPipeline] = useState<boolean>(false);
  const [isCreatingStage, setIsCreatingStage] = useState<boolean>(false);

  const showEditPipelineForm = (pipeline: PipelineEntity) => {
    setIsUpdatingPipeline(true);
    initializeForm({
      formUUID: pipeline.id,
      title: `Edit pipeline: ${pipeline.name}`,
      showModal: true,
      formFields: pipelineFormFields(pipeline),
      entityName: 'Pipeline',
      isUpdateReq: true,
      isCreateReq: false,
      selected: pipeline,
      schema: schema,
    });
  };

  const showCreatePipelineStageForm = (pipeline: PipelineEntity) => {
    setIsCreatingStage(true);
    initializeForm({
      schema: schema,
      showModal: true,
      isCreateReq: true,
      formUUID: pipeline.id,
      selected: pipeline,
      title: 'Add Stage',
      formFields: pipelineStageFormFields(),
      entityName: 'Stage',
      pipelineId: pipeline?.id,
    });
  };

  const handleDeletePipeline = () => {
    if (pipelineToDelete) {
      setIsDeletingPipeline(true);
      httpDelete(`SchemaModule/v1.0/pipelines/${pipelineToDelete.id}`).then((res: any) => {
        setIsDeletingPipeline(false);
        setPipelineToDelete(undefined);
        onUpdate();
      });
    }
  };

  const handleFormSubmit = (params: FormReducerSubmitEvt) => {
    // Update Pipeline
    if (params.data && isUpdatingPipeline) {
      const body = {
        name: params.data.name,
        key: params.data.key,
        description: params.data.description,
        moduleName: schema.moduleName,
        entityName: schema.entityName,
        schemaTypes: params.data.schemaTypes,
      };

      httpPut(`SchemaModule/v1.0/pipelines/${pipeline.id}`, body).then(() => {
        setIsUpdatingPipeline(false);
        onUpdate();
      });
    }
    // Create Stage
    else if (params.data && isCreatingStage) {
      let allowedNextStages: PipelineStageEntity[] = [];

      if (params.data.allowedNextStages?.length! > 0) {
        allowedNextStages =
          pipeline?.stages?.filter((stage: PipelineStageEntity) => {
            return params.data.allowedNextStages.includes(stage.id);
          }) || [];
      }

      const body = {
        name: params.data.name,
        key: params.data.key,
        description: params.data.description,
        isDefault: params.data.isDefault,
        isFail: params.data.isFail,
        isSuccess: params.data.isSuccess,
        color: params.data.color,
        allowedNextStages: allowedNextStages,
      };

      httpPost(`SchemaModule/v1.0/stages/${pipeline.id}`, body).then(() => {
        setIsCreatingStage(false);
        onUpdate();
      });
    }
  };

  return (
    <>
      <Section
        key={pipeline.id}
        title={pipeline.name}
        icon="layout-linear"
        style={{ marginBottom: last ? 0 : 15 }}
        rightElement={
          pipeline && (
            <>
              <Button
                text="Add Stage"
                onClick={() => showCreatePipelineStageForm(pipeline)}
                icon="plus"
              />
              <Button
                text={`Edit Pipeline`}
                onClick={() => showEditPipelineForm(pipeline)}
                icon="edit"
              />
              <Popover
                content={
                  <Menu>
                    <MenuItem
                      key="2"
                      intent="danger"
                      onClick={() => {
                        setPipelineToDelete(pipeline);
                        setConfirmDeleteRecordDialogVisible(true);
                      }}
                      icon="trash"
                      text="Delete Pipeline"
                      disabled={disableDelete}
                    />
                  </Menu>
                }
                placement="bottom"
              >
                <Button rightIcon="caret-down" />
              </Popover>
            </>
          )
        }
      >
        <SectionCard>
          {/* Pipeline Info */}
          <Row>
            <Col span={24}>
              <Descriptions
                column={1}
                bordered
                size="small"
                labelStyle={{ width: '15%', fontWeight: 600, padding: '4px 8px' }}
                contentStyle={{ padding: '4px 8px' }}
              >
                <Descriptions.Item label="Schema Types">
                  {pipeline.schemaTypes?.length! > 0 ? (
                    pipeline.schemaTypes?.map((schemaType: string, i: number) => (
                      <Tag
                        minimal
                        round
                        intent="primary"
                        style={{
                          marginRight: i + 1 === pipeline.schemaTypes?.length! ? 0 : 8,
                        }}
                      >
                        {schemaType}
                      </Tag>
                    ))
                  ) : (
                    <Tag minimal round>
                      ALL
                    </Tag>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          {/* Stages */}
          <Row style={{ marginTop: 15 }}>
            <Col span={24}>
              {<PipelineStagesList schema={schema} pipeline={pipeline} onUpdate={onUpdate} />}
            </Col>
          </Row>
        </SectionCard>
      </Section>

      {/* Edit Pipeline Form */}
      <FormModal
        formUUID={pipeline.id}
        onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
      />

      {/* Delete Pipeline Dialog */}
      <Alert
        intent="danger"
        isOpen={confirmDeleteRecordDialogVisible}
        onClose={() => setConfirmDeleteRecordDialogVisible(false)}
        loading={isDeletingPipeline}
        canEscapeKeyCancel={!isDeletingPipeline}
        canOutsideClickCancel={!isDeletingPipeline}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        onConfirm={handleDeletePipeline}
      >
        <p>
          <span style={{ fontWeight: 600 }}>Deleting Pipeline</span>
        </p>
        <br />
        <p>Please confirm that you want to delete {pipelineToDelete?.name}?</p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: SharedFormReducer) => dispatch(initializeSharedForm(params)),
});

export default connect(mapState, mapDispatch)(PipelineSection);
