export const getBadgeStyleForAppointmentNumber = (appointments: number, isOverBooking: boolean) => {
  if (isOverBooking) {
    // RED
    return {
      backgroundColor: '#FF4647',
      color: 'white',
      marginLeft: 78,
      padding: '2px 5px',
      borderRadius: 3,
      fontWeight: 500,
    };
  } else if (appointments <= 0) {
    // GREY
    return {
      backgroundColor: '#BEBEBE',
      color: 'white',
      marginLeft: 78,
      padding: '2px 5px',
      borderRadius: 3,
      fontWeight: 500,
    };
  } else if (appointments > 0 && appointments < 3) {
    // ORANGE
    return {
      backgroundColor: '#FFA167',
      color: 'white',
      marginLeft: 78,
      padding: '2px 5px',
      borderRadius: 3,
      fontWeight: 500,
    };
  } else {
    // GREEN
    return {
      backgroundColor: '#61BD61',
      color: 'white',
      marginLeft: 78,
      padding: '2px 5px',
      borderRadius: 3,
      fontWeight: 500,
    };
  }
};
