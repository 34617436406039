import {
  Button,
  ButtonGroup,
  Classes,
  InputGroup,
  MenuItem,
  Popover,
  Switch,
  Tooltip,
} from '@blueprintjs/core';
import { DatePicker3 } from '@blueprintjs/datetime2';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Empty, Row, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { ISearchRecords, searchRecordsRequest } from '../../../../../../core/records/store/actions';
import {
  ISchemaByModuleAndEntity,
  getSchemaByModuleAndEntityRequest,
} from '../../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../../core/schemas/store/reducer';
import { httpGet } from '../../../../../../shared/http/requests';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../../shared/utilities/schemaHelpers';
import Stat from '../../../../../../v2/shared/components/Stat';
import { useCalendarState } from '../../context';
import { getWOTypeByLabel } from './helpers';
import HourlyViewAssignWorkOrderModal from './HourlyViewAssignWorkOrderModal';
import HourlyViewEngineerCard from './HourlyViewEngineerCard';
import HourlyViewStatsDrawer from './HourlyViewStatsDrawer';
import HourlyViewUnassignedWorkOrderDrawer from './HourlyViewUnassignedWorkOrderDrawer';
import HourlyViewWorkOrderRow from './HourlyViewWorkOrderRow';
import {
  SET_ALL_WORK_ORDERS,
  SET_ASSIGNING_TO_ENGINEER_ID,
  SET_REMOTE_STATS,
  SET_UNASSIGNED_WORK_ORDERS,
  SET_WORK_ORDERS_TO_ASSIGN,
  TOGGLE_ASSIGN_MODE,
} from './store/constants';
import { IHourlyViewReducer, hourlyViewInitialState, hourlyViewReducer } from './store/reducer';
import './styles.scss';
import { WorkOrderBundle } from './types';

interface Props {
  navigationReducer: any;
  schemaReducer: ISchemaReducer;
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) => void;
  searchRecords: (params: any, cb: any) => void;
  setLastRefreshed: (date: Dayjs | 'loading' | 'stopped') => void;
}

const { FIELD_SERVICE_MODULE, IDENTITY_MODULE } = SchemaModuleTypeEnums;
const { WORK_ORDER } = SchemaModuleEntityTypeEnums;
const TEAM = 'Team';
const USER = 'User';
let STOP_POLLING = false;

export const HourlyViewContext = React.createContext<{
  state: IHourlyViewReducer;
  dispatch: any;
}>({
  state: hourlyViewInitialState,
  dispatch: undefined,
});

let timer: NodeJS.Timeout | undefined = undefined;
const pollingIntervalMilliseconds = 10000;
let isRefreshingWorkOrders = false;
let isRefreshingRemoteStats = false;
let unassignedWODrawerVisible = false;

const HourlyView: React.FC<Props> = (props: Props) => {
  const { navigationReducer, schemaReducer, getSchema, searchRecords } = props;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isLoadingWOs, setIsLoadingWOs] = useState<boolean>(true);

  const [assignedWOs, setAssignedWOs] = useState<any[]>([]);
  const [paginationSize, setPaginationSize] = useState<20 | 50 | 80 | 100>(80);
  const [paginationPage, setPaginationPage] = useState<number>(1);

  const [selectedDate, setSelectedDate] = useState<string>(dayjs().format('YYYY-MM-DD'));

  const [WOSchema, setWOSchema] = useState<SchemaEntity | undefined>(undefined);
  const [WOPipelineStages, setWOPipelineStages] = useState<any[]>([]);
  const [isLoadingPipeline, setIsLoadingPipeline] = useState<boolean>(false);
  const [teamSchema, setTeamSchema] = useState<SchemaEntity | undefined>(undefined);
  const [userSchema, setUserSchema] = useState<SchemaEntity | undefined>(undefined);

  const [selectedWOTypeFilter, setSelectedWOTypeFilter] = useState<string>('All Types');
  const [selectedWOStageFilter, setSelectedWOStageFilter] = useState<string>('All Stages');
  const [selectedTeam, setSelectedTeam] = useState<string>('All Organizations');

  const [teams, setTeams] = useState<DbRecordEntityTransform[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);

  const [isDatePickerVisible, setIsDatePickerVisible] = useState<boolean>(false);

  const { schedules } = useCalendarState();
  const [state, dispatch] = useReducer(hourlyViewReducer, hourlyViewInitialState);

  // Configure polling timer
  const startTimer = () => {
    timer = setInterval(() => {
      if (!document.hidden && !STOP_POLLING) {
        refreshWorkOrders();
        refreshRemoteStats();
      }
    }, pollingIntervalMilliseconds);
  };
  const clearTimer = () => {
    clearInterval(timer);
    timer = undefined;
  };

  useEffect(() => {
    return () => {
      clearTimer();
      STOP_POLLING = false;
      unassignedWODrawerVisible = false;
      props.setLastRefreshed('loading');
    };
  }, []);

  // Fetch WO Schema on component mount
  useEffect(() => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      WORK_ORDER,
    );

    if (shortlistSchema) {
      setWOSchema(shortlistSchema);
    } else {
      getSchema({ moduleName: FIELD_SERVICE_MODULE, entityName: WORK_ORDER }, (res: any) => {
        setWOSchema(res);
      });
    }
  }, []);

  // Update WO Pipeline when WO Schema Type changes
  useEffect(() => {
    setSelectedWOStageFilter('All Stages');
    setWOPipelineStages([]);

    if (WOSchema && selectedWOTypeFilter !== 'All Types') {
      setIsLoadingPipeline(true);
      httpGet(
        `SchemaModule/v1.0/pipelines/bymodule/${WOSchema.moduleName}/${
          WOSchema.entityName
        }?schemaType=${selectedWOTypeFilter.toUpperCase()}`,
      ).then((res: any) => {
        const pipelines = res.data?.data || [];
        if (pipelines.length > 0) {
          setWOPipelineStages(pipelines[0]?.stages);
        }
        setIsLoadingPipeline(false);
      });
    }
  }, [selectedWOTypeFilter, WOSchema]);

  // When Schedules are modified in the filters, clear the assigning work order list
  useEffect(() => {
    dispatch({ type: SET_WORK_ORDERS_TO_ASSIGN, payload: [] });
  }, [schedules]);

  // Fetch Team Schema on component mount
  useEffect(() => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      IDENTITY_MODULE,
      TEAM,
    );

    if (shortlistSchema) {
      setTeamSchema(shortlistSchema);
    } else {
      getSchema({ moduleName: IDENTITY_MODULE, entityName: TEAM }, (res: any) => {
        setTeamSchema(res);
      });
    }
  }, []);

  // Fetch User Schema on component mount
  useEffect(() => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      IDENTITY_MODULE,
      USER,
    );

    if (shortlistSchema) {
      setUserSchema(shortlistSchema);
    } else {
      getSchema({ moduleName: IDENTITY_MODULE, entityName: USER }, (res: any) => {
        setUserSchema(res);
      });
    }
  }, []);

  const refreshWorkOrders = () => {
    if (!isRefreshingWorkOrders && STOP_POLLING === false) {
      props.setLastRefreshed('loading');
      fetchWorkOrders();
      isRefreshingWorkOrders = true;
    }
  };

  const refreshRemoteStats = () => {
    if (!isRefreshingRemoteStats) {
      fetchRemoteStats();
      isRefreshingRemoteStats = true;
    }
  };

  useEffect(() => {
    if (isDatePickerVisible) {
      STOP_POLLING = true;
      !unassignedWODrawerVisible && props.setLastRefreshed(dayjs());
    } else {
      !unassignedWODrawerVisible ? (STOP_POLLING = false) : {};
    }
  }, [isDatePickerVisible]);

  // When Team is selected, fetch users
  useEffect(() => {
    if (selectedTeam !== 'All Organizations' && userSchema) {
      httpGet(
        `${IDENTITY_MODULE}/v1.0/db-associations/${TEAM}/${selectedTeam}/relations?entities=["User"]&withLinks=false`,
      ).then((res: any) => {
        let users = res.data?.data[USER]?.dbRecords || [];
        if (users.length > 0) {
          setUserIds(users.map((user: any) => user.id));
        }
      });
    } else {
      setUserIds([]);
    }
  }, [selectedTeam]);

  // When Team Schema is available, fetch teams
  useEffect(() => {
    if (teamSchema) {
      searchRecords(
        {
          schema: teamSchema,
          searchQuery: {
            terms: '',
            schemas: teamSchema?.id,
            boolean: {
              must: [
                {
                  query_string: {
                    fields: [],
                    query: '*',
                    lenient: true,
                    default_operator: 'AND',
                  },
                },
              ],
            },
          },
        },
        (searchResults: any) => {
          setTeams(searchResults.data?.data || []);
        },
      );
    }
  }, [teamSchema]);

  // Fetch data when dates are changed / component mount, and start the timer
  useEffect(() => {
    setIsLoadingWOs(true);
    setPaginationPage(1);
    fetchWorkOrders();
    fetchRemoteStats();
    clearTimer();
    startTimer();
  }, [selectedDate]);

  const setAllWorkOrders = (WOs: any[]) => {
    dispatch({ type: SET_ALL_WORK_ORDERS, payload: WOs });
  };
  const setUnassignedWorkOrders = (unassignedWOs: any[]) => {
    dispatch({ type: SET_UNASSIGNED_WORK_ORDERS, payload: unassignedWOs });
  };

  const fetchRemoteStats = () => {
    httpGet(
      `FieldServiceModule/v1.0/WorkOrder/appointment-change-reasons?date=${selectedDate}`,
    ).then((res: any) => {
      const stats = res?.data?.data || undefined;

      if (stats) {
        dispatch({
          type: SET_REMOTE_STATS,
          payload: {
            totalCancellations: stats.totalCancellations,
            totalReschedules: stats.totalReschedules,
            requestedByNetomnia: stats.requestedBySummary.NETOMNIA,
            requestedByCustomer: stats.requestedBySummary.CUSTOMER,
            requestedByYouFibre: stats.requestedBySummary.YOUFIBRE,
            rescheduleReasonSummary:
              stats.rescheduleReasonSummary && !Object.keys(stats.rescheduleReasonSummary).length
                ? undefined
                : stats.rescheduleReasonSummary,
            cancelReasonSummary:
              stats.cancelReasonSummary && !Object.keys(stats.cancelReasonSummary).length
                ? undefined
                : stats.cancelReasonSummary,
            dateShiftSummary:
              stats.dateShiftSummary && !Object.keys(stats.dateShiftSummary).length
                ? undefined
                : stats.dateShiftSummary,
          },
        });
      }
    });
  };

  const fetchWorkOrders = () => {
    httpGet(`FieldServiceModule/v1.0/WorkOrder/engineer-work-orders?date=${selectedDate}`).then(
      (res: any) => {
        setIsLoadingWOs(false);
        isRefreshingWorkOrders = false;

        !STOP_POLLING && !unassignedWODrawerVisible && props.setLastRefreshed(dayjs());
        const assigned = res?.data?.data?.assignedWorkOrders || [];
        const unassigned = res?.data?.data?.unassignedWorkOrders || [];

        // Sort unassigned
        if (unassigned.length > 0) {
          let sortedUnassigned = Object.assign([], unassigned);

          sortedUnassigned = unassigned.sort((a: any, b: any) => {
            return a.Address?.dbRecords[0]?.properties?.ExchangeName >
              b.Address?.dbRecords[0]?.properties?.ExchangeName
              ? 1
              : -1;
          });

          setUnassignedWorkOrders(sortedUnassigned);
          // console.log('%cdebug: Unassigned WOs', 'color:orange', sortedUnassigned);
        } else {
          setUnassignedWorkOrders([]);
        }

        if (assigned.length > 0) {
          setAssignedWOs(assigned);
          setAllWorkOrders(assigned);
          // console.log(
          //   '%cdebug: Assigned WOs, sliced sample 0-80',
          //   'color:yellow',
          //   assigned.slice(0, 80),
          // );
        }
      },
    );
  };

  const getStageNameByKey = (key: string) => {
    let stage = WOPipelineStages?.find((P: any) => P.key === key) || undefined;
    if (stage) {
      let truncatedName = stage.name.length > 9 ? stage.name.substring(0, 9) + '...' : stage.name;
      return truncatedName;
    } else {
      return '';
    }
  };

  let filteredWorkOrders: any[] = assignedWOs || [];
  let filteredUnassignedWorkOrders: any[] = state.unassignedWorkOrders || [];

  // If WO Type filtering is set, show only engineers where at least one WO is of that type
  if (selectedWOTypeFilter !== 'All Types') {
    filteredWorkOrders = filteredWorkOrders.filter((WO: WorkOrderBundle) => {
      const WOType = getWOTypeByLabel(selectedWOTypeFilter, WOSchema!);
      return WO.workOrders.some((WO: any) => WO.type === WOType?.name);
    });

    filteredUnassignedWorkOrders = filteredUnassignedWorkOrders.filter(
      (WO: DbRecordEntityTransform) => {
        const WOType = getWOTypeByLabel(selectedWOTypeFilter, WOSchema!);
        return WO.type === WOType?.name;
      },
    );
  }

  // If WO Stage filtering is set, show only engineers where at least one WO is of that stage
  if (selectedWOStageFilter !== 'All Stages') {
    filteredWorkOrders = filteredWorkOrders.filter((WO: WorkOrderBundle) => {
      const stageName = getStageNameByKey(selectedWOStageFilter);
      return WO.workOrders.some((WO: any) => WO.stage?.name === stageName);
    });
  }

  // If WO Organization filtering is set, show only engineers from the selected organization
  if (selectedTeam !== 'All Organizations' && userIds.length > 0) {
    filteredWorkOrders = filteredWorkOrders.filter((WO: WorkOrderBundle) => {
      return userIds.includes(WO.engineer.id);
    });

    // Filter unassigned work orders by selected schedule Ids. Each unassigned WO has a ServiceAppointment property
    // and inside has a dbRecords array. If any of those dbRecords objects has a properties.ScheduleId that is matching
    // selected schedule ids, keep it in the list.
    if (filteredUnassignedWorkOrders.length > 0 && schedules.selectedIds.length > 0) {
      filteredUnassignedWorkOrders = filteredUnassignedWorkOrders.filter((SOA: any) => {
        return SOA.ServiceAppointment?.dbRecords?.some(
          (SA: { id: string; title: string; properties: any }) =>
            schedules.selectedIds.includes(SA.properties?.ScheduleId),
        );
      });
    }
  }

  // Take in a string and capitalize first letter
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Filter by search query (engineer name, WO#, Address)
  if (searchQuery) {
    filteredWorkOrders = filteredWorkOrders.filter((WO: WorkOrderBundle) => {
      return (
        WO.engineer?.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        WO.workOrders.some(
          (WO: any) =>
            WO.recordNumber.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
            WO.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1,
        )
      );
    });
    if (filteredWorkOrders.length > 20) {
      filteredWorkOrders = filteredWorkOrders.slice(0, 100);
    }

    filteredUnassignedWorkOrders = filteredUnassignedWorkOrders.filter(
      (WO: DbRecordEntityTransform) => {
        return (
          // Work Order record number OR title (address)
          (WO.recordNumber &&
            WO.recordNumber?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1) ||
          (WO.title && WO.title?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1)
        );
      },
    );
  }

  // If there are selected schedules, filter both assigned and unassigned work orders by schedules
  if (schedules.selectedIds.length > 0) {
    filteredWorkOrders = filteredWorkOrders.filter((WO: WorkOrderBundle) => {
      return WO.engineer.schedules?.some((schedule: { id: string; title: string }) =>
        schedules.selectedIds.includes(schedule.id),
      );
    });
    filteredUnassignedWorkOrders = filteredUnassignedWorkOrders.filter((SOA: any) => {
      return SOA.ServiceAppointment?.dbRecords?.some(
        (SA: { id: string; title: string; properties: any }) =>
          schedules.selectedIds.includes(SA.properties.ScheduleId),
      );
    });
  }

  // Find the engineer with the most WOs in the view
  let maxWOsPerEngineerInTheView: any = {};
  filteredWorkOrders.forEach((WO: WorkOrderBundle) => {
    if (!maxWOsPerEngineerInTheView?.workOrders?.length) {
      maxWOsPerEngineerInTheView = WO;
    } else if (WO.workOrders?.length > maxWOsPerEngineerInTheView?.workOrders?.length) {
      maxWOsPerEngineerInTheView = WO;
    }
  });

  const isLeftPaginationDisabled = () => {
    if (filteredWorkOrders.length <= 80) {
      return true;
    } else if (paginationPage === 1) {
      return true;
    } else {
      return false;
    }
  };

  const isRightPaginationDisabled = () => {
    if (filteredWorkOrders.length <= 80) {
      return true;
    } else if (paginationPage === Math.ceil(filteredWorkOrders.length / paginationSize)) {
      return true;
    } else if (filteredWorkOrders.length <= 20) {
      return true;
    } else {
      return false;
    }
  };

  // Slice data for pagination
  let tableData: any[] = assignedWOs || [];
  if (filteredWorkOrders.length > 80) {
    tableData = filteredWorkOrders.slice(
      paginationPage * paginationSize - paginationSize,
      paginationSize * paginationPage,
    );
  } else {
    tableData = filteredWorkOrders;
  }

  const getViewportHeight = () => {
    return 'calc(100vh - 220px)';
  };

  const EngineerRow = ({ index, key, style }: any): any => {
    const WO = tableData[index];

    return (
      <Row
        key={key}
        style={style}
        // onDragOverCapture={(e: any) => {
        //   e.stopPropagation();
        //   e.preventDefault();
        //   if (state.assigningToEngineerId !== WO.engineer.id) {
        //     setDraggingToEngineerId(WO.engineer.id);
        //   }
        // }}
        // onDragLeave={(e: any) => {
        //   if (state.assigningToEngineerId === WO.engineer.id) {
        //     e.stopPropagation();
        //     e.preventDefault();
        //     setDraggingToEngineerId(undefined);
        //   }
        // }}
        // onDrop={(e: any) => {
        //   e.stopPropagation();
        //   e.preventDefault();
        //   toggleAssignWorkOrderDialog();
        // }}
        // className={state.assigningToEngineerId === WO.engineer.id ? 'draggedOver' : ''}
      >
        <HourlyViewEngineerCard
          key={'EngineerCard' + index}
          hideTopBorder={index === 0 ? true : false}
          percentageCompleted={WO.completionSummary.percentageComplete || 0}
          engineer={WO.engineer}
        />

        <Col span={20} key={`EngineerRowCol2${index}`}>
          <div
            key={`EngineerRowDiv1${index}`}
            style={{
              backgroundColor: '#E9E9E9',
              minWidth: maxWOsPerEngineerInTheView?.workOrders?.length * 320 || 'auto',
            }}
          >
            <HourlyViewWorkOrderRow
              key={'WORow' + index}
              engineerId={tableData[index]?.engineer?.id}
              WO={WO!}
              searchQuery={searchQuery}
              WOType={getWOTypeByLabel(selectedWOTypeFilter, WOSchema!)}
              WOStage={getStageNameByKey(selectedWOStageFilter)}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const renderEventTypeOrganization: ItemRenderer<any> = (
    event,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={selectedTeam !== 'All Organizations' && selectedTeam === event.value}
        disabled={modifiers.disabled}
        key={event.rank}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={event.label}
      />
    );
  };

  const renderEventTypeWOType: ItemRenderer<any> = (
    event,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={selectedWOTypeFilter !== 'All Types' && selectedWOTypeFilter === event.value}
        disabled={modifiers.disabled}
        key={event.rank}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={event.label}
      />
    );
  };

  const renderEventTypeWOStage: ItemRenderer<any> = (
    event,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={selectedWOTypeFilter !== 'All Stages' && selectedWOStageFilter === event.value}
        disabled={modifiers.disabled}
        key={event.rank}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={event.label}
      />
    );
  };

  const getWOTypeFilters = (): any[] => {
    if (WOSchema && WOSchema?.types?.length! > 0) {
      let response = WOSchema.types.map((type: any) => ({
        label: capitalizeFirstLetter(type.label),
        value: type.label,
        key: type.name,
      }));
      response.unshift({ value: 'All Types', key: '', label: 'All Types' });
      return response;
    } else {
      return [];
    }
  };

  const getWOStageFilters = (): any[] => {
    if (WOPipelineStages) {
      let response = WOPipelineStages.map((stage: any) => ({
        label: stage.name,
        value: stage.key,
        key: stage.key,
      }));
      response.unshift({ value: 'All Stages', key: '', label: 'All Stages' });
      return response;
    } else {
      return [];
    }
  };

  const getTeamMenuItems = (): any[] => {
    let menuItems = teams
      .sort((a: any, b: any) => a.title.localeCompare(b.title))
      .map((team: any) => ({
        label: team.title,
        value: team.id,
        key: team.id,
      }));

    // Add 'All Organizations' to the top of the list
    menuItems.unshift({
      label: 'All Organizations',
      value: 'All Organizations',
      key: 'All Organizations',
    });

    return menuItems;
  };

  const areFiltersApplied = () => {
    return (
      selectedWOTypeFilter !== 'All Types' ||
      selectedTeam !== 'All Organizations' ||
      searchQuery.length
    );
  };

  const getTeamNameById = (id: string) => {
    const team = teams.find((team: any) => team.id === id);

    // If team name is longer than 15 characters, truncate with ...
    if (team && team.title && team?.title?.length > 15) {
      return team?.title?.substring(0, 15) + '...';
    } else {
      return team?.title || '';
    }
  };

  const WOStats = (): {
    completed: number;
    unCompleted: number;
    inRed: number;
    completionPercentage: string;
  } => {
    let WOTotal: DbRecordEntityTransform[] = [];
    let WOCompleted: DbRecordEntityTransform[] = [];

    // If Filters are applied, show stats for filtered WOs
    if (areFiltersApplied()) {
      WOTotal = filteredWorkOrders.map((WO: any) => WO.workOrders).flat();
      WOCompleted = filteredWorkOrders.map((WO: any) => WO.workOrders).flat();

      if (selectedWOTypeFilter !== 'All Types') {
        WOCompleted = WOCompleted.filter(
          (WO: any) => WO.type === selectedWOTypeFilter.toUpperCase(),
        );
        WOCompleted = WOCompleted.filter((WO: any) => WO.stage?.name === 'Complete');
        WOTotal = WOTotal.filter((WO: any) => WO.type === selectedWOTypeFilter.toUpperCase());
      } else {
        WOCompleted = WOCompleted.filter((WO: any) => WO.stage?.name === 'Complete');
      }
    }
    // Otherwise, show for all WOs that came back
    else {
      WOTotal = assignedWOs.map((WO: any) => WO.workOrders).flat();
      WOCompleted = assignedWOs.map((WO: any) => WO.workOrders).flat();
      WOCompleted = WOCompleted.filter((WO: any) => WO.stage?.name === 'Complete');
    }
    return {
      completed: WOCompleted.length,
      unCompleted: WOTotal.length - WOCompleted.length,
      inRed: WOTotal.filter((WO: any) => WO.sla?.status === 'RED').length,
      completionPercentage:
        WOCompleted.length && WOTotal.length
          ? ((WOCompleted.length / WOTotal.length) * 100).toFixed(1) + '%'
          : '0%',
    };
  };

  return (
    <HourlyViewContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <HourlyViewAssignWorkOrderModal WOSchema={WOSchema} />
      <Row>
        {state.isAssigningWorkOrders && (
          <Col span={5} style={{ opacity: isLoadingWOs ? 0.3 : 1 }}>
            <HourlyViewUnassignedWorkOrderDrawer
              unassignedWorkOrders={filteredUnassignedWorkOrders}
            />
          </Col>
        )}
        <Col span={state.isAssigningWorkOrders ? 19 : 24}>
          {/* Filtering Header */}
          <Row
            align="middle"
            style={{
              background: 'white',
              border: '1px solid #DCDCDD',
              textAlign: 'right',
              padding: '4px 10px',
            }}
          >
            {/* Quick Search Engineers */}
            <Col span={4} style={{ paddingRight: 20, paddingLeft: 3 }}>
              <InputGroup
                disabled={isLoadingWOs || !assignedWOs.length}
                round
                leftIcon="search"
                placeholder="Name, WO#, Address"
                value={searchQuery}
                onChange={(e: any) => setSearchQuery(e.target.value)}
                rightElement={
                  searchQuery.length! > 0 ? (
                    <Button
                      minimal
                      intent="danger"
                      icon="cross"
                      onClick={() => setSearchQuery('')}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            </Col>
            {/* Stats */}
            <Col span={state.isAssigningWorkOrders ? 7 : 10} style={{ textAlign: 'left' }}>
              <Row>
                {/* Show/hide Unassigned */}
                <Col span={state.isAssigningWorkOrders ? 5 : 3}>
                  <span style={{ fontSize: 11, opacity: 0.6 }}>Assigning</span>
                  <br />
                  <Switch
                    large
                    disabled={isLoadingWOs}
                    checked={state.isAssigningWorkOrders}
                    onChange={() => {
                      if (state.isAssigningWorkOrders) {
                        STOP_POLLING = false;
                        unassignedWODrawerVisible = false;
                        dispatch({ type: TOGGLE_ASSIGN_MODE, payload: true });
                        dispatch({ type: SET_WORK_ORDERS_TO_ASSIGN, payload: [] });
                        dispatch({ type: SET_ASSIGNING_TO_ENGINEER_ID, payload: undefined });
                        props.setLastRefreshed('loading');
                      } else {
                        props.setLastRefreshed('stopped');
                        unassignedWODrawerVisible = true;
                        STOP_POLLING = true;
                        dispatch({ type: TOGGLE_ASSIGN_MODE, payload: true });
                      }
                    }}
                    style={{ marginTop: 2 }}
                  />
                </Col>

                <Col span={state.isAssigningWorkOrders ? 5 : 3}>
                  <Stat
                    title="Completed"
                    tooltip="Number of Completed Work Orders"
                    value={isLoadingWOs ? '...' : WOStats().completed || 0}
                  />
                </Col>

                {/* Uncompleted */}
                <Col span={state.isAssigningWorkOrders ? 6 : 3}>
                  <Stat
                    title="Uncompleted"
                    tooltip="Number of Uncompleted Work Orders"
                    value={isLoadingWOs ? '...' : WOStats().unCompleted || 0}
                  />
                </Col>

                {/* In Red */}
                <Col span={state.isAssigningWorkOrders ? 3 : 2}>
                  <Stat
                    title="In Red"
                    tooltip="Total Work Orders in Red SLA status"
                    value={isLoadingWOs ? '...' : WOStats().inRed || 0}
                  />
                </Col>

                {/* Completed */}
                {!state.isAssigningWorkOrders && (
                  <>
                    <Col span={3}>
                      <Stat
                        title="Completion"
                        tooltip="Percentage of all completed Work Orders"
                        value={isLoadingWOs ? '...' : WOStats().completionPercentage || '0%'}
                      />
                    </Col>
                    <Col span={4}>
                      <Stat
                        title="Cancellations"
                        tooltip="Number of Total Cancellations"
                        value={isLoadingWOs ? '...' : state.remoteStats?.totalCancellations || 0}
                      />
                    </Col>
                    <Col span={4}>
                      <Stat
                        title="Reschedules"
                        tooltip="Number of Total Reschedules"
                        value={isLoadingWOs ? '...' : state.remoteStats?.totalReschedules || 0}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Col>
            {/* Day Navigation / Filters */}
            <Col
              span={state.isAssigningWorkOrders ? 13 : 10}
              style={{ textAlign: 'right', height: '100%' }}
            >
              <ButtonGroup>
                <HourlyViewStatsDrawer />

                {/* Organization Filter */}
                <Select
                  scrollToActiveItem
                  activeItem={selectedTeam}
                  disabled={teams.length === 0 || isLoadingWOs}
                  items={getTeamMenuItems()}
                  itemRenderer={renderEventTypeOrganization}
                  filterable={false}
                  onItemSelect={(e: any) => {
                    dispatch({ type: SET_WORK_ORDERS_TO_ASSIGN, payload: [] });
                    setSelectedTeam(e.value);
                  }}
                >
                  <Button
                    disabled={teams.length === 0 || isLoadingWOs}
                    style={{ marginRight: 10 }}
                    intent={selectedTeam === 'All Organizations' ? 'none' : 'primary'}
                    text={
                      selectedTeam === 'All Organizations'
                        ? 'All Organizations'
                        : getTeamNameById(selectedTeam)
                    }
                    rightIcon="caret-down"
                  />
                </Select>

                {/* WO Stage Filter */}
                <Tooltip
                  content="In order to filter by stage, you must first select a Work Order Type."
                  disabled={selectedWOTypeFilter !== 'All Types'}
                >
                  <Select
                    disabled={
                      isLoadingWOs ||
                      !WOPipelineStages.length ||
                      isLoadingPipeline ||
                      selectedWOTypeFilter === 'All Types'
                    }
                    items={getWOStageFilters()}
                    itemRenderer={renderEventTypeWOStage}
                    activeItem={selectedWOStageFilter}
                    scrollToActiveItem
                    filterable={false}
                    onItemSelect={(e: any) => {
                      dispatch({ type: SET_WORK_ORDERS_TO_ASSIGN, payload: [] });
                      setSelectedWOStageFilter(e.value);
                    }}
                  >
                    <Button
                      disabled={
                        isLoadingWOs ||
                        !WOPipelineStages.length ||
                        isLoadingPipeline ||
                        selectedWOTypeFilter === 'All Types'
                      }
                      intent={selectedWOStageFilter === 'All Stages' ? 'none' : 'primary'}
                      style={{ marginRight: 10 }}
                      text={
                        selectedWOStageFilter === 'All Stages'
                          ? 'All Stages'
                          : getStageNameByKey(selectedWOStageFilter)
                      }
                      rightIcon="caret-down"
                    />
                  </Select>
                </Tooltip>

                {/* WO Type Filter */}
                <Select
                  disabled={!WOSchema || isLoadingWOs}
                  items={getWOTypeFilters()}
                  itemRenderer={renderEventTypeWOType}
                  activeItem={selectedWOTypeFilter}
                  scrollToActiveItem
                  filterable={false}
                  onItemSelect={(e: any) => {
                    dispatch({ type: SET_WORK_ORDERS_TO_ASSIGN, payload: [] });
                    setSelectedWOTypeFilter(e.value);
                  }}
                >
                  <Button
                    disabled={!WOSchema || isLoadingWOs}
                    intent={selectedWOTypeFilter === 'All Types' ? 'none' : 'primary'}
                    style={{ marginRight: 10 }}
                    text={
                      selectedWOTypeFilter === 'All Types'
                        ? 'All Types'
                        : capitalizeFirstLetter(selectedWOTypeFilter).length > 8
                        ? capitalizeFirstLetter(selectedWOTypeFilter).substring(0, 8) + '...'
                        : capitalizeFirstLetter(selectedWOTypeFilter)
                    }
                    rightIcon="caret-down"
                  />
                </Select>

                {/* Date Picker */}
                <Button
                  disabled={isLoadingWOs}
                  icon="caret-left"
                  onClick={() => {
                    setSelectedDate(dayjs(selectedDate).subtract(1, 'day').format('YYYY-MM-DD'));
                  }}
                />
                <Popover
                  isOpen={isDatePickerVisible}
                  onClose={() => setIsDatePickerVisible(false)}
                  openOnTargetFocus
                  hoverCloseDelay={900}
                  position="bottom-left"
                  disabled={isLoadingWOs}
                  content={
                    <DatePicker3
                      value={new Date(selectedDate)}
                      highlightCurrentDay
                      className={Classes.ELEVATION_1}
                      onChange={(selectedDate: Date | null, isUserChange: boolean) => {
                        // Only set the date if the user has changed it
                        if (!isUserChange) return;
                        setSelectedDate(dayjs(selectedDate).format('YYYY-MM-DD'));
                        setIsDatePickerVisible(false);
                      }}
                    />
                  }
                >
                  <Button
                    style={{ width: 100 }}
                    disabled={isLoadingWOs}
                    onClick={() => setIsDatePickerVisible(!isDatePickerVisible)}
                    text={dayjs(selectedDate).format('DD/MM/YYYY')}
                  />
                </Popover>
                <Button
                  disabled={isLoadingWOs}
                  icon="caret-right"
                  onClick={() => {
                    setSelectedDate(dayjs(selectedDate).add(1, 'day').format('YYYY-MM-DD'));
                  }}
                />
              </ButtonGroup>
            </Col>
          </Row>

          <Spin spinning={isLoadingWOs} tip="Loading Work Orders">
            <div
              style={{
                marginTop: 0,
                height: getViewportHeight(),
                border: '1px solid #DCDCDD',
                borderTop: 'none',
              }}
            >
              {!isLoadingWOs && tableData.length === 0 && (
                <Empty description="There are no Work Orders to show" style={{ paddingTop: 80 }} />
              )}

              {!isLoadingWOs && tableData.length > 0 && (
                <AutoSizer>
                  {({ height, width }: any) => (
                    <List
                      width={width}
                      height={height}
                      itemCount={tableData.length}
                      itemSize={78}
                      overscanCount={8}
                    >
                      {EngineerRow}
                    </List>
                  )}
                </AutoSizer>
              )}
            </div>

            <Row align="middle">
              <Col span={24} style={{ textAlign: 'right', paddingTop: 10 }}>
                <ButtonGroup>
                  <span style={{ marginLeft: 7, marginRight: 10, fontSize: 12, marginTop: 2 }}>
                    Page {paginationPage} / {Math.ceil(filteredWorkOrders.length / paginationSize)}
                  </span>

                  <Button
                    small
                    icon="step-backward"
                    style={{ marginRight: 8 }}
                    disabled={isLeftPaginationDisabled()}
                    onClick={() => setPaginationPage(1)}
                  />

                  <Button
                    small
                    icon="caret-left"
                    style={{ marginRight: 8 }}
                    disabled={isLeftPaginationDisabled()}
                    onClick={() => setPaginationPage(paginationPage - 1)}
                  />
                  <Button
                    small
                    icon="caret-right"
                    onClick={() => setPaginationPage(paginationPage + 1)}
                    disabled={isRightPaginationDisabled()}
                  />
                </ButtonGroup>
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
    </HourlyViewContext.Provider>
  );
};

const mapState = (state: any) => ({
  navigationReducer: state.navigationReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(HourlyView);
