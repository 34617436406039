import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Descriptions, Modal, Row, Spin } from 'antd';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { OutcomeFormBody } from '../../../../containers/FieldServiceModule/containers/OutcomeFormDetailView/OutcomeFormBody';
import CommunicationsHistory from '../../../../core/notifications/components/CommunicationsHistory';
import ActivityCenter from '../../../../core/records/components/ActivityCenter';
import CollaboratorsCard from '../../../../core/records/components/CollaboratorsCard';
import StageHistory from '../../../../core/records/components/StageHistory';
import { IRecordReducer } from '../../../../core/records/store/reducer';
import { renderDynamicAssociationTables } from '../../../../core/recordsAssociations/helpers/component-helpers';
import CardWithTabs from '../../../../shared/components/CardWithTabs';
import GroupDetails from '../../../../shared/components/GroupDetails';
import { getAllSchemaAssociationSchemas } from '../../../../shared/utilities/recordHelpers';
import { DetailViewContext } from '../../components/DetailViewContextProvider';
import HeaderDetailView from '../../components/HeaderDetailView';
import RecordStagePipeline from '../../components/RecordStagePipeline';
import CustomDetailActions from './CustomDetailActions';
import DetailsConfigurator from './DetailsConfigurator';
import { getSchemasForDefaultDetailView } from './helpers';
import NoteFeed from '../../components/NoteFeed';
import SchemaActionPageHeader from '../../components/SchemaActions/SchemaActionPageHeader';
import { schemaHasType } from '../../../../shared/utilities/schemaHelpers';
import OutcomeFormLauncher from '../../components/OutcomeFormLauncher';
import AssociationDataTable from '../../../../core/recordsAssociations/components/AssociationDataTable';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import HTMLTemplate from '../../components/HTMLTemplate';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SUPPORT_TASK } from '../../components/NoteFeed/types';

interface Props {
  banner?: React.ReactNode;
  customTabBodies?: Object;
  customTabs?: { key: string; tab: string }[];
  defaultTabKey?: string;
  fullWidth?: boolean;
  hideActivityTab?: boolean;
  headerExtras?: React.ReactNode[];
  hideNotesTab?: boolean;
  leftColumn?: React.ReactNode[];
  middleColumnBottom?: React.ReactNode[];
  middleColumnTop?: React.ReactNode[];
  recordReducer: IRecordReducer;
  rightColumn?: React.ReactNode[];
  showCollaborators?: boolean;
  showCommunicationHistory?: boolean;
  isQuickView?: boolean;
}
const { SUPPORT_MODULE } = SchemaModuleTypeEnums;

const DetailView: React.FC<Props> = (props: Props) => {
  const {
    banner,
    customTabBodies,
    customTabs,
    defaultTabKey,
    fullWidth,
    headerExtras,
    hideActivityTab,
    hideNotesTab,
    leftColumn,
    middleColumnBottom,
    middleColumnTop,
    recordReducer,
    rightColumn,
    showCollaborators,
    showCommunicationHistory,
    isQuickView,
  } = props;

  const { viewConfigurations, isLoadingConfigurations, record, schema } =
    useContext(DetailViewContext);

  // check if there is a hash in url named Notes
  const hash = window?.location?.hash;

  // Certain schemas have OUTCOME_FORM type, find those schemas and filter them out from relatedSchemas
  let schemasWithOutcomeForm: SchemaEntity[] =
    getAllSchemaAssociationSchemas(schema?.associations)?.filter((relatedSchema: SchemaEntity) =>
      schemaHasType(relatedSchema, 'OUTCOME_FORM'),
    ) || [];

  const isHTMLTemplate = schema?.types?.some(
    (type: SchemaTypeEntity) => type.name === 'HTML_TEMPLATE',
  );

  // Get related schemas but remove those that have type constraint that doesn't match the record type
  const relatedSchemas = getAllSchemaAssociationSchemas(schema?.associations, []).filter(
    (schemaAssociation: SchemaEntity) => {
      if (
        schemaAssociation.isVisibleInTabs &&
        getSchemasForDefaultDetailView(schema!, record).includes(schemaAssociation.name)
      ) {
        return true;
      }
    },
  );

  const hasSupportTasks = () => {
    return relatedSchemas.some(
      (schema: SchemaEntity) =>
        schema.moduleName === SUPPORT_MODULE && schema.entityName === SUPPORT_TASK,
    );
  };

  const getDefaultTab = () => {
    if (hash === '#Notes' && !hideNotesTab) {
      return 'Notes';
    } else if (record?.type === 'OUTCOME_FORM') {
      return 'Form';
    } else if (defaultTabKey) {
      return defaultTabKey;
    } else {
      return 'Details';
    }
  };

  const shouldShowRightColumn = () => {
    return showCollaborators || showCommunicationHistory || rightColumn;
  };

  return (
    <>
      <Row style={{ marginTop: 1 }}>
        {/* Top Header */}
        <Col span={24}>
          <HeaderDetailView
            isQuickView={isQuickView}
            record={record!}
            extra={[
              record && schema && (
                <SchemaActionPageHeader
                  moduleName={schema.moduleName}
                  entityName={schema.entityName}
                  recordId={record.id}
                  launcherType="INLINE"
                />
              ),
              ...(headerExtras || []),
            ]}
          />
          {record?.stage && <RecordStagePipeline className="record-pipeline" record={record} />}
        </Col>
        {banner && (
          <Col span={24} style={{ paddingLeft: 15, paddingRight: 15 }}>
            {banner}
          </Col>
        )}

        {/* Optional Left Column */}
        {leftColumn && (
          <Col xs={24} md={6} style={{ padding: 15 }}>
            {leftColumn}
          </Col>
        )}

        {/* Main Section Column */}
        <Col
          xs={24}
          md={leftColumn ? 12 : fullWidth || !shouldShowRightColumn() ? 24 : 18}
          style={{
            paddingTop: 15,
            paddingLeft: leftColumn ? 0 : 15,
            paddingRight: fullWidth || !shouldShowRightColumn() ? 15 : 1,
          }}
          className="bp-background"
        >
          {/* Support Tasks */}
          {/*{hasSupportTasks() && <TaskList parentRecord={record} />}*/}

          {/* Custom Middle Columns */}
          {middleColumnTop && <div>{middleColumnTop}</div>}

          <CardWithTabs
            title="Options"
            recordId={record?.id}
            moduleName={record?.entity?.split(':')[0]}
            entityName={record?.entity?.split(':')[1]}
            extra={[headerExtras, <CustomDetailActions record={record} schema={schema!} />]}
            defaultTabKey={getDefaultTab()}
            relatedAssociations={renderDynamicAssociationTables(record, relatedSchemas)}
            hideSchemaActions
            hideCardHeader
            tabList={[
              { key: 'Details', tab: 'Details' },
              !hideActivityTab
                ? {
                    key: 'Activity',
                    tab: 'Activity',
                  }
                : { key: '', tab: '' },
              !hideNotesTab
                ? {
                    key: 'Notes',
                    tab: 'Notes',
                  }
                : { key: '', tab: '' },
              ...(customTabs || []),
              ...(record?.type === 'OUTCOME_FORM'
                ? [
                    {
                      key: 'Form',
                      tab: 'Form',
                    },
                  ]
                : []),
              ...(schemasWithOutcomeForm.length > 0
                ? [
                    {
                      key: 'OutcomeForm',
                      tab: 'Outcome Form',
                    },
                  ]
                : []),
            ]}
            tabContents={{
              ...customTabBodies,
              Details: (
                <div style={{ opacity: isLoadingConfigurations ? 0.3 : 1 }}>
                  {/* Record Properties */}
                  <DetailsConfigurator record={record} viewConfiguration={viewConfigurations[0]} />
                  {/* Groups */}
                  <Descriptions
                    style={{ marginTop: 12 }}
                    bordered
                    size="small"
                    contentStyle={{ fontSize: '0.9em', padding: '4px 6px', width: '80%' }}
                    labelStyle={{
                      fontSize: '0.9em',
                      padding: '4px 6px',
                      fontWeight: 500,
                      width: '20%',
                    }}
                    column={2}
                  >
                    <Descriptions.Item key="Groups" label="Record Groups" span={1}>
                      <GroupDetails record={record} type="FLEX" />
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              ),
              Activity: !hideActivityTab ? (
                <ActivityCenter record={record!} schema={schema!} hideEmailTab />
              ) : (
                <></>
              ),
              Notes: !hideNotesTab ? <NoteFeed record={record!} /> : <></>,
              Form: <OutcomeFormBody schema={schema!} record={record!} />,
              OutcomeForm:
                schemasWithOutcomeForm.length > 0 ? (
                  <>
                    <OutcomeFormLauncher
                      parentRecord={record!}
                      parentSchema={schema!}
                      columns={2}
                    />
                    {schemasWithOutcomeForm?.map((schema) => (
                      <AssociationDataTable
                        collapsedByDefault
                        showAllRecords
                        hideIcon
                        title={schema?.entityName}
                        record={record}
                        moduleName={schema?.moduleName}
                        entityName={schema?.entityName}
                      />
                    ))}
                  </>
                ) : (
                  <></>
                ),
            }}
          />

          {isHTMLTemplate && (
            <HTMLTemplate
              sourceRecord={record}
              schema={schema}
              isEmailTemplate={schema.entityName === 'EmailTemplate'}
            />
          )}
          {middleColumnBottom && <div style={{ marginTop: 15 }}>{middleColumnBottom}</div>}
        </Col>
        {/* Right Column */}
        {shouldShowRightColumn() && (
          <Col xs={24} md={6}>
            <div style={{ padding: 15 }} className="bp-background">
              {showCollaborators && (
                <div style={{ paddingBottom: 15 }}>
                  <CollaboratorsCard record={record} key={record.id} />
                </div>
              )}

              {record?.stage && (
                <div style={{ paddingBottom: 15 }}>
                  <StageHistory record={record} schema={schema!} />
                </div>
              )}

              {showCommunicationHistory && (
                <div style={{ paddingBottom: 15 }}>
                  <CommunicationsHistory record={record} schema={schema!} />
                </div>
              )}

              {rightColumn && <div>{rightColumn}</div>}
            </div>
          </Col>
        )}
      </Row>

      <Modal open={recordReducer.isExportingAssociations} centered={true} footer={null}>
        <Spin spinning={recordReducer.isExportingAssociations}>data exporting...</Spin>
      </Modal>
      <Row gutter={{ xs: 8, sm: 14, md: 14, lg: 14 }}>
        <Col xs={24} sm={24} md={24} lg={18}></Col>
      </Row>
    </>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
});

export default connect(mapState)(DetailView);
