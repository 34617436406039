import { Layout } from 'antd';
import { FC, useContext } from 'react';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import DetailView from '../../../../shared/views/DetailView';
import { ServiceAppointmentConfigCalendar } from '../../components/ServiceAppointmentConfigCalendar';

const ServiceAppointmentConfigDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  return (
    <Layout>
      <DetailView
        showCollaborators
        hideActivityTab
        headerExtras={[]}
        defaultTabKey={'Calendar'}
        customTabs={[
          {
            key: 'Calendar',
            tab: 'Calendar',
          },
        ]}
        customTabBodies={{
          Calendar: <ServiceAppointmentConfigCalendar record={record} />,
        }}
      />
    </Layout>
  );
};

export default ServiceAppointmentConfigDetailView;
