import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Row, Table } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import {
  batchCreatePermissionsBySchemaId,
  batchDeletePermissionsBySchemaId,
} from '../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { Button, Section } from '@blueprintjs/core';

interface Props {
  schema: SchemaEntity | undefined;
  schemaReducer: ISchemaReducer;
  batchCreate: any;
  batchDelete: any;
}

interface State {}

class SchemasPermissions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  batchCreatePermissions = () => {
    const { batchCreate, schema } = this.props;

    if (schema) {
      batchCreate({ schemaId: schema.id }, (result: any) => {
        // Fetch new schema from API
      });
    }
  };

  batchDeletePermissions = () => {
    const { batchDelete, schema } = this.props;
    if (schema) {
      batchDelete({ schemaId: schema.id }, (result: any) => {
        // Fetch new schema from API
      });
    }
  };

  render() {
    const { schema, schemaReducer } = this.props;

    const formsTableColumns = [
      { title: 'Name', dataIndex: 'name' },
      { title: 'Description', dataIndex: 'description' },
    ];

    return (
      <Section
        title="Schema Permissions"
        rightElement={
          schema?.permissions?.length === 0 ? (
            <Button
              key="1"
              style={{ marginLeft: 'auto' }}
              intent="primary"
              onClick={() => this.batchCreatePermissions()}
              loading={schemaReducer.isRequesting}
            >
              Enable access control
            </Button>
          ) : (
            <Button
              key="2"
              style={{ marginLeft: 'auto' }}
              intent="danger"
              onClick={() => this.batchDeletePermissions()}
              loading={schemaReducer.isRequesting}
            >
              Disable access control
            </Button>
          )
        }
      >
        <Row>
          <Col span={24}>
            <Table
              size="small"
              loading={schemaReducer.isRequesting}
              dataSource={schema?.permissions as any}
              columns={formsTableColumns as any}
            />
          </Col>
        </Row>
      </Section>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  batchCreate: (params: any, cb: any) => dispatch(batchCreatePermissionsBySchemaId(params, cb)),
  batchDelete: (params: any, cb: any) => dispatch(batchDeletePermissionsBySchemaId(params, cb)),
});

export default connect(mapState, mapDispatch)(SchemasPermissions);
