import {
  CANCEL_APPOINTMENT_RECORD_REQUEST,
  CLOSE_CANCEL_APPOINTMENT_MODAL,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_WORK_ORDER_AND_APPOINTMENT_REQUEST,
  INITIALIZE_CANCEL_APPOINTMENT_MODAL,
  LOAD_AVAILABLE_APPOINTMENTS_REQUEST,
  SET_SCHEDULE_ID,
  UPDATE_APPOINTMENT_REDUCER,
} from './constants';
import { ISetScheduleId, ServiceAppointmentCreateDto } from './reducer';

export interface ICreateServiceAppointment {
  workOrderId: any;
  createUpdate: ServiceAppointmentCreateDto;
}

export interface ILoadAppointments {
  start: string;
  end: string;
  type: string | undefined;
  addressId?: string;
  exPolygonId?: string;
  scheduleId?: string;
  scheduleIds?: string[];
  isOverview?: boolean;
}

export function loadTimeSlotsRequest(params: ILoadAppointments, cb?: any) {
  return {
    type: LOAD_AVAILABLE_APPOINTMENTS_REQUEST,
    params,
    cb,
  };
}

export function createAppointmentRequest(params: ICreateServiceAppointment, cb: any) {
  return {
    type: CREATE_APPOINTMENT_REQUEST,
    params,
    cb,
  };
}

export function cancelAppointmentRequest(params: any, cb: any) {
  return {
    type: CANCEL_APPOINTMENT_RECORD_REQUEST,
    params,
    cb,
  };
}

export function initailizeCancelAppointmentModal(params: any) {
  // console.log('[DebugX] initializeCancelAppointmentModal', params);
  return {
    type: INITIALIZE_CANCEL_APPOINTMENT_MODAL,
    params,
  };
}

export function setScheduleId(params: ISetScheduleId) {
  return {
    type: SET_SCHEDULE_ID,
    params,
  };
}

export function closeCancelAppointmentModal() {
  return {
    type: CLOSE_CANCEL_APPOINTMENT_MODAL,
  };
}

export function updateAppointmentReducer(params: any) {
  return {
    type: UPDATE_APPOINTMENT_REDUCER,
    params,
  };
}

export function creteWorkOrderAndAppointmentRequest(params: any, cb = (data: any, err: any) => {}) {
  return {
    type: CREATE_WORK_ORDER_AND_APPOINTMENT_REQUEST,
    params,
    cb,
  };
}
