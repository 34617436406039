import { Alert, Button, Collapse, Divider, EntityTitle, Section, Spinner } from '@blueprintjs/core';
import { OrganizationUserEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/organization.user.entity';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { EditUser, editUserRequest } from '../../../../../core/identityUser/store/actions';
import SharedFormModal, {
  FormReducerSubmitEvt,
} from '../../../../../shared/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '../../../../../shared/components/SharedForm/store/actions';
import { httpDelete, httpGet, httpPatch } from '../../../../../shared/http/requests';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { DepartmentViewUsersDialog } from '../DepartmentUserViewDialog';
import { formFields } from '../FormFields';

interface Props {
  department: any;
  initializeForm: (params: any) => void;
  onUpdate: () => void;
  onDelete: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const DepartmentDetails: FC<Props> = (props: Props) => {
  const { department, onUpdate, initializeForm, alertMessage, onDelete } = props;
  const [isShowingFullDetails, setIsShowingFullDetails] = useState<boolean>(true);
  const [isDeleteDepartmentAlertVisible, setIsDeleteDepartmentAlertVisible] =
    useState<boolean>(false);
  const [copiedTextToClipboard, setCopiedTextToClipboard] = useState<boolean>(false);

  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<OrganizationUserEntity[]>([]);

  useEffect(() => {
    if (department) {
      setUsers([]);
      getAssociatedUsers(department.id);
    }
  }, [department]);

  const getAssociatedUsers = async (departmentId: string) => {
    setIsLoadingUsers(true);
    try {
      const usersResponse = await httpGet(`IdentityModule/v2.0/departments/${departmentId}/users`);
      const users = usersResponse.data?.data || [];
      if (users.length > 0) {
        setUsers(users);
      }
      setIsLoadingUsers(false);
    } catch (e: any) {
      alertMessage({ body: 'Could not fetch associated users. ' + e.message, type: 'error' });
      setIsLoadingUsers(false);
    }
  };

  const showEditDepartmentForm = () => {
    const tempEl: any = department;
    let editForm: any[] = [];

    formFields.forEach((element: any) => {
      editForm.push({
        allowClear: element.allowClear,
        allowSearch: element.allowSearch,
        customValidation: element.customValidation,
        customValidationMessage: element.customValidationMessage,
        initialValue: tempEl[element.property],
        isDisabled: element.isDisabled,
        isHidden: element.isHidden,
        isRequired: element.isRequired,
        label: element.label,
        message: element.message,
        options: element.options,
        property: element.property,
        type: element.type,
        value: tempEl[element.property],
      });
    });

    initializeForm({
      formUUID: department.id,
      title: 'Edit Department',
      recordId: department?.id,
      showModal: true,
      formFields: editForm,
      entityName: 'Department',
      isUpdateReq: true,
      columns: 1,
    });
  };

  const editDepartment = async (body: any) => {
    try {
      await httpPatch(`IdentityModule/v2.0/departments/${department.id}`, body);
      alertMessage({ body: 'Department updated', type: 'success' });
      onUpdate();
    } catch (e: any) {
      alertMessage({
        body: 'There was a problem updating the department. ' + e.message,
        type: 'error',
      });
    }
  };

  const handleFormSubmit = (params: any) => {
    if (params.title === 'Edit Department') {
      editDepartment(params.data);
    }
  };

  const deleteDepartment = async () => {
    try {
      await httpDelete(`IdentityModule/v2.0/departments/${department.id}`);
      alertMessage({ body: 'Department deleted', type: 'success' });
      onDelete();
    } catch (e: any) {
      alertMessage({
        body: 'There was a problem deleting the department. ' + e.message,
        type: 'error',
      });
    }
  };

  const copyTextToClipboard = (text: any) => {
    navigator.clipboard.writeText(text);
    setCopiedTextToClipboard(true);
    setTimeout(() => {
      setCopiedTextToClipboard(false);
    }, 1500);
  };

  return (
    <>
      <Row style={{ padding: 15 }}>
        <SharedFormModal
          formUUID={department.id}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />

        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h3 style={{ marginTop: 0 }}>{department.name}</h3>
              <span>{department.description}</span>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginTop: 20, marginBottom: 0 }}>
          <Row gutter={8}>
            <Col span={12}>
              <Button
                icon="trash"
                intent="danger"
                text="Delete Department"
                disabled
                fill
                onClick={() => setIsDeleteDepartmentAlertVisible(true)}
              />
            </Col>
            <Col span={12}>
              <Button
                text="Edit Department"
                fill
                icon="annotation"
                onClick={showEditDepartmentForm}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Users */}
        <Col span={24} className="detailViewSection">
          <Section
            title={`Users ${users.length ? `(${users.length})` : ''}`}
            rightElement={
              !isLoadingUsers && users.length > 0 ? (
                <DepartmentViewUsersDialog department={department} users={users} />
              ) : (
                <></>
              )
            }
          >
            <Row>
              {/* Users list */}
              {users.slice(0, 3)?.map((user: any) => (
                <Col span={24} key={user.id}>
                  <EntityTitle key={user.id} icon="person" title={user.name} />
                </Col>
              ))}
              {/* Show how many more Users  */}
              {users.length > 3 && (
                <Col span={24} style={{ marginTop: 8 }}>
                  <span>{`+ ${users.length - 3} more`}</span>
                </Col>
              )}
              {!users.length && (
                <Col span={24}>
                  <span>
                    {isLoadingUsers ? (
                      <Spinner size={15} style={{ justifyContent: 'start' }} />
                    ) : (
                      'None'
                    )}
                  </span>
                </Col>
              )}
            </Row>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Department ID */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Department Id"
            rightElement={
              <Button
                minimal
                small
                rightIcon={copiedTextToClipboard ? 'tick' : null}
                text={copiedTextToClipboard ? 'Copied' : 'Copy'}
                intent={copiedTextToClipboard ? 'success' : 'primary'}
                onClick={() => copyTextToClipboard(department.id)}
              />
            }
          >
            <span>{department.id}</span>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Full Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Full Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingFullDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingFullDetails(!isShowingFullDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingFullDetails}>
                  <Row>
                    {/* Updated At */}
                    <Col span={24} style={{ marginTop: 5 }}>
                      <EntityTitle
                        title="Updated At"
                        subtitle={
                          <span>
                            {dayjs(department.updatedAt).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>

                    {/* Created At */}
                    <Col span={24} style={{ marginTop: 10, marginBottom: 20 }}>
                      <EntityTitle
                        title="Created At"
                        subtitle={
                          <span>
                            {dayjs(department.createdAt).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>
      </Row>

      {/* Delete Department Alert */}
      <Alert
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={isDeleteDepartmentAlertVisible}
        onCancel={() => setIsDeleteDepartmentAlertVisible(false)}
        onClose={() => setIsDeleteDepartmentAlertVisible(false)}
        onConfirm={deleteDepartment}
      >
        <p>Are you sure you want to delete this Department? This action cannot be undone.</p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  editUser: (params: EditUser, cb: any) => dispatch(editUserRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(DepartmentDetails);
