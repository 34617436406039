import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Table } from 'antd';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  ISchemaActionsList,
  deleteSchemaAction,
  getSchemaActionsList,
} from '../../../../../core/schemas/store/actions';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import SchemaActionCreateUpdateModal from '../SchemaActionCreateUpdateModal';
import { toggleSchemaActionCreateEditDrawer } from '../../../../../core/userInterface/store/actions';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { Alert, Button, InputGroup, Section, Tag, Tooltip } from '@blueprintjs/core';
import { renderBooleanValue } from '../../../../../v2/shared/helpers/UIHelpers';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
interface Props {
  schema: SchemaEntity | undefined;
  getActions: (payload: ISchemaActionsList, cb: any) => void;
  toggleDrawer: () => void;
  deleteAction: (payload: { schemaActionId: string }, cb?: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface DataType {
  key: string;
  name: string;
  actionType: string;
  schemaType: string;
  action: any;
  description: string;
  stages: string[];
  targetStages: string[];
  defaultForm: boolean;
  userAction: boolean;
}

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;

const SchemaActionList: FC<Props> = (props) => {
  const { schema, getActions, toggleDrawer, deleteAction, alertMessage } = props;

  const [actionList, setActionList] = useState<SchemaActionEntity[]>([]);
  const [isLoadingList, setIsLoadingList] = useState<boolean>(true);
  const [actionToUpdate, setActionToUpdate] = useState<SchemaActionEntity | undefined>(undefined);
  const [isDeletingAction, setIsDeletingAction] = useState<boolean>(false);
  const [deleteActionAlertVisible, setDeleteActionAlertVisible] = useState<boolean>(false);
  const [deleteActionId, setDeleteActionId] = useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const getSchemaTypeName = (schema: SchemaEntity, typeId: string) => {
    const target = schema.types.find((type: SchemaTypeEntity) => type.id === typeId);
    if (target) {
      return target.name;
    } else {
      return '';
    }
  };

  // Fetch actions when Schema is provided to the component
  useEffect(() => {
    if (schema) {
      getActionsForList(schema);
    }
  }, [schema]);

  const onUpdateOrCreate = () => {
    if (schema) {
      getActionsForList(schema);
    }
  };

  const getActionsForList = (schema: SchemaEntity) => {
    getActions({ schemaId: schema?.id }, (data: SchemaActionEntity[]) => {
      setActionList(data);
      console.log('debug: schema action list', data);
      setIsLoadingList(false);
    });
  };

  const deleteSchemaAction = () => {
    setIsDeletingAction(true);

    if (deleteActionId) {
      deleteAction({ schemaActionId: deleteActionId }, (res: any) => {
        if (res) {
          setIsDeletingAction(false);

          const newActionList = actionList.filter((action: any) => action.id !== deleteActionId);
          setActionList(newActionList);

          alertMessage({
            body: 'Schema Action Deleted',
            type: 'success',
          });

          closeModal();
          if (schema) {
            getActionsForList(schema);
          }
        } else {
          alertMessage({
            body: 'Schema Action could not be Deleted',
            type: 'error',
          });
          closeModal();
          setIsDeletingAction(false);
        }
      });
    }
  };

  const setActionForUpdate = (action: SchemaActionEntity) => {
    if (action) {
      setActionToUpdate(action);
      toggleDrawer();
    }
  };

  const clearActionForUpdate = () => {
    setActionToUpdate(undefined);
  };

  // Table Columns
  const tableColumns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      defaultSortOrder: 'ascend' as 'descend' | 'ascend' | null,
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => (
        <Link to={`/${SCHEMA_MODULE}/SchemaAction/${schema?.id}/${record.key}`}>{text}</Link>
      ),
    },
    {
      title: 'Description',
      width: '20%',
      ellipsis: true,
      dataIndex: 'description',
      key: 'description',
      defaultSortOrder: 'ascend' as 'descend' | 'ascend' | null,
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
      render: (text: any, record: any) => text,
    },
    {
      title: 'Type',
      dataIndex: 'actionType',
      key: 'actionType',
      width: '8%',
      align: 'center',
      defaultSortOrder: 'ascend' as 'descend' | 'ascend' | null,
      sorter: (a: any, b: any) => getActionType(a).localeCompare(getActionType(b)),
      render: (text: any, record: any) => text,
    },
    {
      title: 'Schema Type',
      dataIndex: 'schemaType',
      key: 'schemaType',
      align: 'center',
      width: '10%',
      render: (text: any, record: any) => text,
    },
    {
      title: 'Stages',
      dataIndex: 'stages',
      align: 'center',
      key: 'stages',
      ellipsis: false,
      render: (text: any, record: any) =>
        record.stages?.length! > 0 ? (
          record.stages.map((stageKey: string, i: number) => (
            <>
              <Tag intent="primary" minimal>
                {stageKey}
              </Tag>
              <br />
            </>
          ))
        ) : (
          <></>
        ),
    },
    {
      title: 'Target Stages',
      dataIndex: 'targetStages',
      align: 'center',
      key: 'targetStages',
      render: (text: any, record: any) =>
        record.targetStages?.length! > 0 ? (
          record.targetStages.map((stageKey: string, i: number) => (
            <>
              <Tag intent="success" minimal>
                {stageKey}
              </Tag>
              <br />
            </>
          ))
        ) : (
          <></>
        ),
    },
    {
      title: 'Default Form',
      dataIndex: 'defaultForm',
      align: 'center',
      key: 'defaultForm',
      width: '5%',
      render: (text: any, record: any) => renderBooleanValue(record.defaultForm ? 'true' : 'false'),
    },
    {
      title: 'User Action',
      dataIndex: 'userAction',
      align: 'center',
      key: 'userAction',
      width: '5%',
      render: (text: any, record: any) => renderBooleanValue(record.userAction ? 'true' : 'false'),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '8%',
      key: 'actions',
      ellipsis: false,
      align: 'right',
      render: (text: any, record: any) => (
        <>
          <Tooltip hoverOpenDelay={1000} content="View Definition" key={record.key}>
            <Link to={`/${SCHEMA_MODULE}/SchemaAction/${schema?.id}/${record.key}`}>
              <Button
                icon="list-columns"
                key={record.key}
                intent="primary"
                outlined
                small
                style={{ marginRight: 8 }}
              />
            </Link>
          </Tooltip>

          <Tooltip hoverOpenDelay={1000} content="Edit" key={record.key}>
            <Button
              key={record.key}
              icon="edit"
              onClick={() => setActionForUpdate(record.action)}
              intent="primary"
              outlined
              small
              style={{ marginRight: 8 }}
            />
          </Tooltip>
          <Tooltip hoverOpenDelay={1000} content="Delete" key={record.key}>
            <Button
              key={record.key}
              icon="trash"
              outlined
              intent="danger"
              small
              onClick={() => {
                setDeleteActionAlertVisible(true);
                setDeleteActionId(record.key);
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const getActionType = (action: any) => {
    if (action.isCreate) {
      return 'CREATE';
    } else if (action.isUpdate) {
      return 'UPDATE';
    } else if (action.isStepFlow) {
      return 'FLOW';
    } else {
      return 'NONE';
    }
  };

  // Table Data
  let tableData: DataType[] = [];
  if (actionList.length > 0 && schema) {
    tableData = actionList.map((action: any) => {
      return {
        key: action.id,
        name: action.name,
        actionType: getActionType(action),
        schemaType: getSchemaTypeName(schema, action.schemaTypeId) || 'DEFAULT',
        defaultForm: action.defaultForm,
        userAction: action.userAction,
        action: action,
        description: action.description,
        stages: action.stages,
        targetStages: action.targetStages,
      };
    });
  }

  if (searchTerm) {
    tableData = tableData.filter((row: DataType) => {
      return row.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }

  const closeModal = () => {
    setDeleteActionAlertVisible(false);
    setDeleteActionId(undefined);
  };

  return (
    <>
      <Alert
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={deleteActionAlertVisible}
        onCancel={closeModal}
        onClose={closeModal}
        onConfirm={deleteSchemaAction}
        loading={isDeletingAction}
      >
        <p>Are you sure you want to delete this schema action? This action cannot be undone.</p>
      </Alert>

      <Section
        title="Schema Actions"
        rightElement={
          <>
            <InputGroup
              round
              id="text-input"
              style={{ width: 200 }}
              placeholder="Search Actions"
              leftIcon="search"
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
            />
            <Button intent="primary" onClick={toggleDrawer}>
              Create Schema Action
            </Button>
          </>
        }
      >
        <Col span={24}>
          <Table
            size="small"
            bordered={true}
            loading={isLoadingList}
            columns={tableColumns}
            dataSource={tableData}
            pagination={{ pageSize: 20 }}
            style={{ fontSize: '0.8em' }}
          />
        </Col>
        <SchemaActionCreateUpdateModal
          isCreate={!actionToUpdate}
          actionToUpdate={actionToUpdate}
          schemaId={schema?.id}
          onSuccess={onUpdateOrCreate}
          onCancel={clearActionForUpdate}
        />
      </Section>
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getActions: (payload: ISchemaActionsList, cb: any) => dispatch(getSchemaActionsList(payload, cb)),
  deleteAction: (payload: { schemaActionId: string }, cb: any) =>
    dispatch(deleteSchemaAction(payload, cb)),
  toggleDrawer: () => dispatch(toggleSchemaActionCreateEditDrawer()),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(SchemaActionList);
