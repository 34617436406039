import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Button, Col, Row, Tag } from 'antd';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { canUserAccessModule } from '../../permissions/rbacRules';

const maxGroupsInTheView: number = 5;

interface Props {
  record: DbRecordEntityTransform | undefined;
  userReducer: any;
  type?: 'INLINE' | 'FLEX';
}

const GroupDetails: FC<Props> = (props: Props) => {
  const { record, userReducer, type } = props;
  const [viewExpanded, setViewExpanded] = useState<boolean>(false);

  const canAccess = canUserAccessModule(userReducer, 'identitymanagermodule');

  const renderGroupTags = () => {
    if (record) {
      let filteredGroups: { id: string; name: string }[] = [];

      // If record has more groups than we allow, place Show More button
      if (record.groups?.length! > maxGroupsInTheView && !viewExpanded) {
        filteredGroups = Object.assign(record.groups!.slice(0, maxGroupsInTheView));
      } else {
        filteredGroups = Object.assign(record.groups!);
      }

      return (
        <>
          {filteredGroups.map((group: any, index: number) => (
           <Tag key={group?.id} style={{
             marginBottom: 8,
             cursor: canAccess ? 'pointer' : 'auto'
           }}>
              {canAccess ? (
                <Link to={`/IdentityManagerModule/Groups/${group.id}`}>{group.name}</Link>
              ) : (
                <span>{group.name}</span>
              )}
            </Tag>
          ))}
          {record.groups!.length! > maxGroupsInTheView ? (
            <Button
              type="link"
              onClick={() => setViewExpanded(!viewExpanded)}
              style={{ fontSize: '0.9em', padding: '0 5px', height: 'auto' }}
            >
              {viewExpanded
                ? 'Show less'
                : `+ ${record.groups!.length! - filteredGroups.length} more`}
            </Button>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return <></>;
    }
  };

  const renderBody = () => {
    if (type === 'FLEX') {
      return (
        <Row>
          <Col span={24} style={{ fontSize: '0.9em' }}>
            {record?.groups?.length! > 0 ? renderGroupTags() : 'No Groups'}
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col span={24} style={{ marginBottom: 10 }}>
            <span style={{ fontWeight: 600 }}>Groups:</span>
          </Col>
          {/* Render Group tags, or nothing. */}
          <Col span={24}>{record?.groups?.length! > 0 ? renderGroupTags() : 'No Groups'}</Col>
        </Row>
      );
    }
  };

  return !record ? <></> : renderBody();
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(GroupDetails);
