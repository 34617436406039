import {
  Button,
  Callout,
  DialogStep,
  MultistepDialog,
  Section,
  SectionCard,
  Spinner,
} from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Descriptions } from 'antd';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { initializeRecordForm } from '../../../../../core/records/components/Forms/store/actions';
import { httpDelete, httpGet, httpPost } from '../../../../../shared/http/requests';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import './styles.scss';
import CoreForm from '../../../../../core/records/components/Forms/CoreForm';
import { getOdinSchemaByEntity } from '../../../../../shared/utilities/schemaHelpers';

interface PropsType {
  record: DbRecordEntityTransform;
  recordFormReducer: any;
  initializeForm: any;
  openDialog?: boolean;
  onClose?: any;
  onConfirm?: any;
  alertMessage: (params: { body: string; type: string }) => void;
  isDisabled?: boolean;
}

const stepOneFormUUID = uuidv4();
const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;

const CancelRemediationAppointment: FunctionComponent<PropsType> = (props) => {
  const {
    record,
    openDialog,
    initializeForm,
    recordFormReducer,
    onClose,
    onConfirm,
    alertMessage,
    isDisabled,
  } = props;

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(true);
  const [dialogState, setDialogState] = React.useState<any>({});
  const [serviceAppointment, setServiceAppointment] = React.useState<
    DbRecordEntityTransform | undefined
  >(undefined);
  const [isConfirmLoading, setIsConfirmLoading] = React.useState<boolean>(false);
  const [schemaActions, setSchemaActions] = React.useState<SchemaActionEntity[]>([]);
  const [stepError, setError] = React.useState<any>(undefined);

  const [changeReasonSchema, setChangeReasonSchema] = React.useState<SchemaEntity | undefined>(
    undefined,
  );

  function resetState() {
    setError(undefined);
    setIsNextDisabled(true);
    setServiceAppointment(undefined);
    setIsConfirmLoading(false);
    setDialogState({
      stepOne: {
        formData: undefined,
      },
      stepTwo: {
        formData: undefined,
      },
    });
  }

  useEffect(() => {
    if (openDialog) {
      setIsDialogOpen(openDialog);
    }
  }, [openDialog]);

  useEffect(() => {
    if (isDialogOpen) {
      fetchData();
    }
  }, [isDialogOpen]);

  useEffect(() => {
    fetchData();
  }, [record]);

  async function fetchData() {
    const schema = await getOdinSchemaByEntity(FIELD_SERVICE_MODULE, 'ChangeReason');
    setChangeReasonSchema(schema);

    if (record) {
      httpGet(
        `FieldServiceModule/v1.0/db-associations/WorkOrder/${record.id}/one-relation?entity=ServiceAppointment&withLinks=false`,
      )
        .then(
          (
            res: AxiosResponse<{
              ServiceAppointment: { dbRecords: DbRecordEntityTransform[] };
            }>,
          ) => {
            if (res.data['ServiceAppointment']?.dbRecords) {
              setServiceAppointment(res.data['ServiceAppointment']?.dbRecords[0]);
            }
          },
        )
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });
    }

    httpGet(`SchemaModule/v1.0/schemas-actions`)
      .then((res) => {
        setSchemaActions(res.data.data);
      })
      .catch((err) => {
        console.error('Error loading table data:', err);
      });
  }

  const cancelWorkOrderAptAction = schemaActions?.find((action: SchemaActionEntity) => {
    return action.name === 'CancelRemediationWorkOrderAppointment';
  });

  function setupStep(newStep: string) {
    let stepNumber = 1;
    if (newStep === 'step-one') {
      stepNumber = 1;
    } else if (newStep === 'step-two') {
      stepNumber = 2;
    } else if (newStep === 'confirm') {
      stepNumber = 3;
    }

    setError(undefined);

    switch (stepNumber) {
      case 1:
        if (!cancelWorkOrderAptAction) {
          console.error('cancelWorkOrderAptAction action not found');
          return;
        }

        initializeForm({
          formUUID: stepOneFormUUID,
          schemaActionId: cancelWorkOrderAptAction.id,
          showFormModal: true,
          hideRecordFormFields: true,
          showInitializing: false,
          isCreateReq: true,
          isCloning: true,
          schema: changeReasonSchema,
          modified: dialogState.stepOne?.formData ? [dialogState.stepOne?.formData] : [],
          sections: [
            {
              name: changeReasonSchema?.name,
              schema: changeReasonSchema,
              associations: [
                {
                  entity: record.entity,
                  recordId: record.id,
                },
              ],
            },
          ],
        });
        break;
      case 3:
        setDialogState({
          ...dialogState,
          stepOne: {
            formData: {
              ...recordFormReducer?.modified[0],
              entity: 'FieldServiceModule:ChangeReason',
              type: 'SA_CANCEL',
              properties: {
                ...recordFormReducer?.modified[0]?.properties,
                TimeBlock: getProperty(serviceAppointment, 'TimeBlock'),
                AppointmentDate: getProperty(serviceAppointment, 'Date'),
                EngineerName: getProperty(record, 'EngineerName'),
                EngineerId: getProperty(record, 'EngineerId'),
                Contractor: getProperty(record, 'Contractor'),
                ScheduleId: getProperty(record, 'ScheduleId'),
              },
              associations: [
                ...recordFormReducer?.modified[0]?.associations,
                ...recordFormReducer?.sections[0]?.associations,
                {
                  entity: 'FieldServiceModule:ServiceAppointmentConfig',
                  recordId: getProperty(record, 'ScheduleId'),
                },
                {
                  entity: 'IdentityModule:User',
                  recordId: getProperty(record, 'EngineerId'),
                },
              ],
            },
          },
        });
        break;
      default:
        break;
    }
  }

  async function handleFinalStepSubmit() {
    setError(undefined);
    setIsConfirmLoading(true);
    // Add additional data to the form data
    try {
      await httpPost(`FieldServiceModule/v1.0/db/bulk-create`, {
        recordsToCreate: [dialogState?.stepOne?.formData],
      });

      if (serviceAppointment) {
        await httpDelete(`FieldServiceModule/v1.0/db/bulk-delete`, {
          recordsToDelete: [
            {
              entity: 'FieldServiceModule:ServiceAppointment',
              recordId: serviceAppointment.id,
            },
          ],
        });
      }

      // Wait for 2 seconds and then fetch the new data

      setIsDialogOpen(false);
      setIsConfirmLoading(false);

      alertMessage({
        body: 'Appointment canceled successfully',
        type: 'success',
      });

      if (onConfirm) {
        onConfirm();
      }
    } catch (err: any) {
      setIsConfirmLoading(false);
      setError(err?.response?.data?.message);
      console.error('Error loading tab data:', err);
    }
  }

  return (
    <>
      <Button
        intent="danger"
        outlined
        onClick={() => setIsDialogOpen(true)}
        disabled={isConfirmLoading || !serviceAppointment || isDisabled}
      >
        Cancel Appointment
      </Button>
      <MultistepDialog
        isOpen={isDialogOpen}
        canOutsideClickClose={false}
        showCloseButtonInFooter={true}
        className="multistep-dialog-cancel-appointment"
        icon="info-sign"
        navigationPosition="top"
        onClose={() => {
          if (onClose) {
            onClose();
          }
          resetState();
          setIsDialogOpen(false);
        }}
        onOpened={() => setupStep('step-one')}
        onChange={(newStep: string) => {
          setupStep(newStep);
          setIsNextDisabled(true);
        }}
        nextButtonProps={{
          disabled: isNextDisabled,
          // tooltipContent: this.state.value === undefined ? "Select an option to continue" : undefined,
        }}
        finalButtonProps={{
          onClick: () => handleFinalStepSubmit(),
        }}
        title={'Cancel Appointment'}
      >
        <DialogStep
          id="step-one"
          title="Cancel Reason"
          panel={
            <Section>
              <SectionCard>
                {!cancelWorkOrderAptAction ? (
                  <Callout intent="danger">
                    <p>Could not locate schema action: CancelWorkOrderAppointment</p>
                  </Callout>
                ) : (
                  <CoreForm
                    type="EMBEDDED"
                    formUUID={stepOneFormUUID}
                    isCreateRecord={true}
                    showFormActions={false}
                    isNextDisabled={(isNextDisabled: boolean) => {
                      setIsNextDisabled(isNextDisabled);
                    }}
                  />
                )}
              </SectionCard>
            </Section>
          }
        />
        <DialogStep
          id="confirm"
          panel={
            <Section>
              {isConfirmLoading && (
                <SectionCard>
                  <Callout intent="primary">
                    <Spinner size={50} />
                    <p>Submitting Request</p>
                  </Callout>
                </SectionCard>
              )}

              <SectionCard>
                {stepError && (
                  <Callout intent="danger">
                    <p>{stepError}</p>
                  </Callout>
                )}
                {/*<pre>{JSON.stringify(dialogState, null, 2)}</pre>*/}
                <Descriptions column={1} bordered={true}>
                  <Descriptions.Item label="Canceling">
                    {DateTime.fromISO(serviceAppointment?.properties?.Date).toFormat('d/M/yyyy')}{' '}
                    {serviceAppointment?.properties?.TimeBlock}
                  </Descriptions.Item>
                  <Descriptions.Item label="Requested By">
                    {dialogState?.stepOne?.formData?.properties?.RequestedBy}
                  </Descriptions.Item>
                  <Descriptions.Item label="Reason">
                    {dialogState?.stepOne?.formData?.properties?.CancelReason}
                  </Descriptions.Item>
                  <Descriptions.Item label="Description">
                    {dialogState?.stepOne?.formData?.properties?.Description}
                  </Descriptions.Item>
                </Descriptions>
              </SectionCard>
            </Section>
          }
          title="Confirm"
        />
      </MultistepDialog>
    </>
  );
};

const mapState = (state: any) => ({
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CancelRemediationAppointment);
