import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { ExceptionType } from '@d19n/temp-fe-d19n-common/dist/exceptions/types/ExceptionType';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import {
  CANCEL_APPOINTMENT_RECORD_ERROR,
  CANCEL_APPOINTMENT_RECORD_SUCCESS,
  CLOSE_CANCEL_APPOINTMENT_MODAL,
  CREATE_APPOINTMENT_ERROR,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_SUCCESS,
  INITIALIZE_CANCEL_APPOINTMENT_MODAL,
  LOAD_AVAILABLE_APPOINTMENTS_ERROR,
  LOAD_AVAILABLE_APPOINTMENTS_REQUEST,
  LOAD_AVAILABLE_APPOINTMENTS_SUCCESS,
  SET_SCHEDULE_ID,
  UPDATE_APPOINTMENT_REDUCER,
} from './constants';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { DbRecordCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/dto/db.record.create.update.dto';

export interface ServiceAppointmentCreateDto {
  Date: string | null;
  TimeBlock: 'AM' | 'PM' | null;
  properties?: DbRecordCreateUpdateDto;
  scheduleId?: string;
}

export interface ISetScheduleId {
  id: string | undefined;
}

export interface IAppointmentReducer {
  appointmentModalVisible: boolean;
  cancelModalVisible: boolean;
  cancelRelatedRecord: DbRecordEntityTransform | undefined;
  createUpdate: ServiceAppointmentCreateDto;
  deleteFromDetail: boolean;
  errors: ExceptionType[];
  isCreating: boolean;
  isRequesting: boolean;
  isSearching: boolean;
  isUpdating: boolean;
  list: PipelineEntity[];
  newAppointmentData: any;
  orderId: string | undefined;
  reScheduleServiceAppointment: boolean;
  saveData: any;
  scheduleId: string | undefined;
  schema: SchemaEntity | null;
  schemaType: string | undefined;
  selected: { [key: string]: any } | null;
  shortList: { [key: string]: any } | null;
}

export const initialState: IAppointmentReducer = {
  appointmentModalVisible: false,
  cancelModalVisible: false,
  cancelRelatedRecord: undefined,
  createUpdate: {
    Date: null,
    TimeBlock: null,
  },
  deleteFromDetail: false,
  errors: [],
  isCreating: false,
  isRequesting: false,
  isSearching: false,
  isUpdating: false,
  list: [],
  newAppointmentData: {},
  orderId: undefined,
  reScheduleServiceAppointment: false,
  saveData: {},
  scheduleId: undefined,
  schema: null,
  schemaType: undefined,
  selected: null,
  shortList: {},
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_SCHEDULE_ID: {
      return {
        ...state,
        scheduleId: action.params.id,
      };
    }

    case LOAD_AVAILABLE_APPOINTMENTS_REQUEST: {
      return {
        ...state,
        isSearching: true,
      };
    }
    case LOAD_AVAILABLE_APPOINTMENTS_SUCCESS: {
      return {
        ...state,
        isSearching: false,
        list: action.results.data,
      };
    }
    case LOAD_AVAILABLE_APPOINTMENTS_ERROR: {
      return {
        ...state,
        isSearching: false,
        list: [],
      };
    }

    case CREATE_APPOINTMENT_REQUEST: {
      return {
        ...state,
        isCreating: true,
      };
    }
    case CREATE_APPOINTMENT_SUCCESS: {
      return {
        ...state,
        isCreating: false,
      };
    }
    case CREATE_APPOINTMENT_ERROR: {
      return {
        ...state,
        isCreating: false,
      };
    }

    case INITIALIZE_CANCEL_APPOINTMENT_MODAL: {
      return {
        ...state,
        ...action.params,
      };
    }

    case CANCEL_APPOINTMENT_RECORD_SUCCESS: {
      return {
        ...state,
        cancelModalVisible: false,
        cancelRelatedRecord: undefined,
        deleteFromDetail: false,
      };
    }

    case CANCEL_APPOINTMENT_RECORD_ERROR: {
      return {
        ...state,
        cancelModalVisible: false,
        cancelRelatedRecord: undefined,
      };
    }

    case CLOSE_CANCEL_APPOINTMENT_MODAL: {
      return {
        ...state,
        cancelModalVisible: false,
        cancelRelatedRecord: undefined,
        reScheduleServiceAppointment: false,
        saveData: {},
        newAppointmentData: {},
      };
    }

    case UPDATE_APPOINTMENT_REDUCER: {
      return {
        ...state,
        ...action.params,
      };
    }

    default:
      return state;
  }
}

export default reducer;
