export const UPDATE_SCHEMA_COLUMN_REDUCER = 'UPDATE_SCHEMA_COLUMN_REDUCER';
export const UPDATE_SCHEMA_COLUMN_PROPERTIES = 'UPDATE_SCHEMA_COLUMN_PROPERTIES';

export const REMOVE_SCHEMA_COLUMN_OPTION = 'REMOVE_SCHEMA_COLUMN_OPTION';

export const GET_SCHEMA_COLUMN_REQUEST = 'GET_SCHEMA_COLUMN_REQUEST';
export const GET_SCHEMA_COLUMN_SUCCESS = 'GET_SCHEMA_COLUMN_SUCCESS';
export const GET_SCHEMA_COLUMN_ERROR = 'GET_SCHEMA_COLUMN_ERROR';

export const CREATE_SCHEMA_COLUMN_REQUEST = 'CREATE_SCHEMA_COLUMN_REQUEST';
export const CREATE_SCHEMA_COLUMN_SUCCESS = 'CREATE_SCHEMA_COLUMN_SUCCESS';
export const CREATE_SCHEMA_COLUMN_ERROR = 'CREATE_SCHEMA_COLUMN_ERROR';

export const UPDATE_SCHEMA_COLUMN_REQUEST = 'UPDATE_SCHEMA_COLUMN_REQUEST';
export const UPDATE_SCHEMA_COLUMN_SUCCESS = 'UPDATE_SCHEMA_COLUMN_SUCCESS';
export const UPDATE_SCHEMA_COLUMN_ERROR = 'UPDATE_SCHEMA_COLUMN_ERROR';

export const DELETE_SCHEMA_COLUMN_REQUEST = 'DELETE_SCHEMA_COLUMN_REQUEST';
export const DELETE_SCHEMA_COLUMN_SUCCESS = 'DELETE_SCHEMA_COLUMN_SUCCESS';
export const DELETE_SCHEMA_COLUMN_ERROR = 'DELETE_SCHEMA_COLUMN_ERROR';

export const CREATE_SCHEMA_COLUMN_PERMISSIONS_REQUEST = 'CREATE_SCHEMA_COLUMN_PERMISSIONS_REQUEST';
export const CREATE_SCHEMA_COLUMN_PERMISSIONS_SUCCESS = 'CREATE_SCHEMA_COLUMN_PERMISSIONS_SUCCESS';
export const CREATE_SCHEMA_COLUMN_PERMISSIONS_ERROR = 'CREATE_SCHEMA_COLUMN_PERMISSIONS_ERROR';

export const DELETE_SCHEMA_COLUMN_PERMISSIONS_REQUEST = 'DELETE_SCHEMA_COLUMN_PERMISSIONS_REQUEST';
export const DELETE_SCHEMA_COLUMN_PERMISSIONS_SUCCESS = 'DELETE_SCHEMA_COLUMN_PERMISSIONS_SUCCESS';
export const DELETE_SCHEMA_COLUMN_PERMISSIONS_ERROR = 'DELETE_SCHEMA_COLUMN_PERMISSIONS_ERROR';
