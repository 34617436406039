import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Select } from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../shared/utilities/schemaHelpers';
import { IRecordLookup, lookupRecords } from '../../../records/store/actions';
import { getSchemaByModuleAndEntityRequest, ISchemaByModuleAndEntity } from '../../../schemas/store/actions';

interface Props {
  WORecordType: 'INSTALL' | 'SERVICE' | 'COLLECTION';
  onScheduleSelect: (schedule: DbRecordEntityTransform) => void;
  lookup: (params: IRecordLookup, cb?: any) => void;
  schemaReducer: any;
  userReducer: any;
  addressRecord: any;
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) => void;
  disabled?: boolean;
  onError?: (error: any) => void;
}

const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { SERVICE_APPOINTMENT_CONFIG } = SchemaModuleEntityTypeEnums;

const ScheduleSelect: FC<Props> = (props: Props) => {
  const {
    onScheduleSelect,
    WORecordType,
    addressRecord,
    lookup,
    schemaReducer,
    getSchema,
    disabled,
    onError,
  } = props;
  const [isLoadingSchedules, setIsLoadingSchedules] = useState<boolean>(false);
  const [schedules, setSchedules] = useState<DbRecordEntityTransform[]>([]);
  const [SACSchema, setSACSchema] = useState<SchemaEntity | undefined>(undefined);
  const [selectedSchedule, setSelectedSchedule] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );

  // get SAC schema on component mount
  useEffect(() => {
    const shortListSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      SERVICE_APPOINTMENT_CONFIG,
    );
    if (shortListSchema) {
      setSACSchema(shortListSchema);
    } else {
      getSchema(
        {
          moduleName: FIELD_SERVICE_MODULE,
          entityName: SERVICE_APPOINTMENT_CONFIG,
        },
        (res: SchemaEntity) => {
          if (res) {
            setSACSchema(res);
          }
        },
      );
    }
  }, []);

  // When schema is available and WORecord is available, fetch schedules
  useEffect(() => {
    if (SACSchema) {
      getScheduleAppointments();
    }
  }, [SACSchema]);

  const canScheduleInArea = (schedule: DbRecordEntityTransform) => {
    let isValid = false;
    if (getProperty(schedule, 'ExPolygonId')) {
      isValid =
        getProperty(schedule, 'ExPolygonId')?.indexOf(getProperty(addressRecord, 'ExPolygonId')) >
        -1;
    }
    if (!getProperty(schedule, 'ExPolygonId')) {
      isValid = true;
    }
    return isValid;
  };

  // Get schedule appointments and filter by Available from/to date + WO type
  const getScheduleAppointments = () => {
    if (SACSchema && WORecordType && addressRecord) {
      onError && onError(undefined);

      lookup(
        {
          schema: SACSchema,
          query: {
            entity: `${FIELD_SERVICE_MODULE}:${SERVICE_APPOINTMENT_CONFIG}`,
            properties: [],
          },
        },
        (res: DbRecordEntityTransform[]) => {
          const allowedSchedules: any[] = [WORecordType];

          if (['INSTALL_BUSINESS'].includes(WORecordType)) {
            allowedSchedules.push('INSTALL');
          } else if (['REMEDIATION', 'COLLECTION', 'INSPECTION'].includes(WORecordType)) {
            allowedSchedules.push('SERVICE');
          } else {
            allowedSchedules.push('INSTALL_SERVICE');
          }
          // Get all schedules that are available before current date and last
          // either indefinite or after the current date. Filter schedules to
          // include the same exPolygonId as the Address record that we fetched.
          // Also, return only appointments that match the record type "INSTALL" | "SERVICE"
          const filteredResults = res?.filter((schedule: DbRecordEntityTransform) => {
            const today = moment();
            const availableFrom = getProperty(schedule, 'AvailableFrom');
            const availableTo = getProperty(schedule, 'AvailableTo');

            // If Schedule is valid, return or throw an error
            if (
              moment(availableFrom, 'YYYY-MM-DD').isSameOrBefore(today) &&
              (!availableTo || moment(availableTo, 'YYYY-MM-DD').isSameOrAfter(today)) &&
              allowedSchedules.includes(schedule?.type) &&
              canScheduleInArea(schedule)
            ) {
              return schedule;
            }
          });

          // Set state after schedules found
          if (filteredResults.length > 0) {
            const selectedSchedule: DbRecordEntityTransform | undefined =
              filteredResults?.find(
                (elem: DbRecordEntityTransform) => getProperty(elem, 'IsDefault') === 'true',
              ) || undefined;
            setSchedules(filteredResults);
            setSelectedSchedule(selectedSchedule);
            setIsLoadingSchedules(false);
            onScheduleSelect && selectedSchedule && onScheduleSelect(selectedSchedule);
          }
          // There are no schedules, throw an error
          else {
            setIsLoadingSchedules(false);
            onError &&
              onError(
                <span>
                  There are no schedules available to book a {WORecordType}. Please contact the
                  YouFibre install team.
                </span>,
              );
          }
        },
      );
    }
  };

  const handleSelectedSchedule = (e: any) => {
    const selectedSchedule = schedules.find((schedule: DbRecordEntityTransform) => {
      return schedule.id === e;
    });
    if (selectedSchedule) {
      setSelectedSchedule(selectedSchedule);
      onScheduleSelect && onScheduleSelect(selectedSchedule);
    }
  };

  return (
    <Select
      style={{ width: '100%', textAlign: 'left' }}
      disabled={isLoadingSchedules || disabled}
      loading={isLoadingSchedules}
      defaultValue={selectedSchedule?.id}
      value={selectedSchedule?.id}
      onChange={(e: any) => handleSelectedSchedule(e)}
    >
      {schedules?.map((appointment: DbRecordEntityTransform) => {
        return (
          <Select.Option key={appointment.title} value={appointment.id}>
            {appointment.title}
          </Select.Option>
        );
      })}
    </Select>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  lookup: (params: IRecordLookup, cb: () => {}) => dispatch(lookupRecords(params, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(ScheduleSelect);
