import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row, Space, Table } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateSchema,
  createSchemaRequest,
  listSchemasRequest,
} from '../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import FormModal, {
  FormReducerSubmitEvt,
} from '../../../../../shared/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '../../../../../shared/components/SharedForm/store/actions';
import { SharedFormReducer } from '../../../../../shared/components/SharedForm/store/reducer';
import { formFields } from '../FormFields';
import { Button, Icon, InputGroup, Section } from '@blueprintjs/core';
import ModuleEntityIcon from '../../../../../core/theme/ModuleEntityIcon';
import './styles.scss';

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;

interface Props {
  formReducer: SharedFormReducer;
  schemaReducer: ISchemaReducer;
  listSchemas: any;
  initializeForm: any;
  createSchema: (params: CreateSchema) => void;
}

interface State {
  searchTerm: string;
  favorites: string[]; // schema ids
}

const uuid = uuidv4();

class SchemaListView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchTerm: '',
      favorites: [],
    };
  }

  componentDidMount() {
    this.fetchData();
    this.fetchFavorites();
  }

  fetchData() {
    const { listSchemas } = this.props;
    listSchemas();
  }

  async fetchFavorites() {
    const favorites = await localStorage.getItem('OdinSchemaFavorites');
    if (favorites) {
      this.setState({ favorites: JSON.parse(favorites) });
    }
  }

  showCreateForm() {
    const { initializeForm } = this.props;

    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Schema',
      formFields: formFields,
      entityName: 'Schema',
    });
  }

  handleFormSubmit(params: FormReducerSubmitEvt) {
    const { createSchema, formReducer } = this.props;

    if (params.data && !formReducer.isUpdateReq) {
      const body = {
        name: params.data.name,
        description: params.data.description,
        moduleName: params.data.moduleName,
        entityName: params.data.entityName,
        titleCase: params.data.titleCase,
        assignable: false,
        upsertOnCreate: true,
      };

      createSchema({ body });
    }
  }

  addOrRemoveFavorite = (id: string) => {
    let favorites = Object.assign([], this.state.favorites);

    if (favorites.includes(id)) {
      const index = favorites.indexOf(id);
      if (index > -1) {
        favorites.splice(index, 1);
      }
    } else {
      favorites.push(id);
    }

    this.setState({ favorites });
    localStorage.setItem('OdinSchemaFavorites', JSON.stringify(favorites));
  };

  isSchemaFavorite = (id: string) => this.state.favorites.includes(id);

  renderSchemaList() {
    const { schemaReducer } = this.props;

    const moduleFilters = Object.keys(SchemaModuleTypeEnums).map((key) => ({
      // @ts-ignore
      text: SchemaModuleTypeEnums[key as string],
      // @ts-ignore
      value: SchemaModuleTypeEnums[key as string],
    }));

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: any, record: any) => (
          <Space key={record.key + 'space'}>
            <Icon
              className={`schemaFavoriteStar ${
                this.isSchemaFavorite(record.key) ? 'active' : 'inactive'
              }`}
              key={record.key + 'icon'}
              style={{ color: this.isSchemaFavorite(record.key) ? 'orange' : 'black' }}
              icon={this.isSchemaFavorite(record.key) ? 'star' : 'star-empty'}
              onClick={() => this.addOrRemoveFavorite(record.key)}
            />
            <Link key={record.key + 'link'} to={`/${SCHEMA_MODULE}/Schema/${record.key}`}>
              {text}
            </Link>
          </Space>
        ),
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      },
      {
        title: 'Module',
        dataIndex: 'moduleName',
        key: 'moduleName',
        filters: moduleFilters,
        onFilter: (value: any, record: any) => record.moduleName.indexOf(value) === 0,
        sorter: (a: any, b: any) => a.moduleName.localeCompare(b.moduleName),
      },
      {
        title: 'Entity',
        dataIndex: 'entityName',
        key: 'entityName',
        sorter: (a: any, b: any) => a.entityName.localeCompare(b.entityName),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
    ];

    let dataSource = schemaReducer?.list
      ?.map((elem: SchemaEntity) => ({
        key: elem.id,
        name: elem.name,
        moduleName: elem.moduleName,
        entityName: elem.entityName,
        description: elem.description,
      }))
      .sort((a: any, b: any) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    // Apply quick search term
    if (this.state.searchTerm?.length! > 0) {
      dataSource = dataSource.filter((elem: any) => elem.name.indexOf(this.state.searchTerm) > -1);
    }

    // Sort the data table according to the favorites, put favorites on top.
    if (this.state.favorites.length > 0) {
      const favorites = dataSource.filter((elem: any) => this.state.favorites.includes(elem.key));
      const nonFavorites = dataSource.filter(
        (elem: any) => !this.state.favorites.includes(elem.key),
      );
      dataSource = favorites.concat(nonFavorites);
    }

    return (
      <div>
        <Table
          size="small"
          style={{ minHeight: '100%' }}
          pagination={{ defaultPageSize: 100 }}
          scroll={{ y: 'calc(100vh - 230px)' }}
          dataSource={dataSource}
          columns={columns}
        />
      </div>
    );
  }

  render() {
    return (
      <>
        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => this.handleFormSubmit(params)}
        />
        <Row>
          <Col span={24}>
            <Section
              title="Schemas"
              subtitle="Schema Manager"
              rightElement={
                <>
                  <InputGroup
                    rightElement={
                      this.state.searchTerm.length > 0 ? (
                        <Button
                          minimal
                          intent="danger"
                          icon="cross"
                          onClick={() => this.setState({ searchTerm: '' })}
                        />
                      ) : (
                        <></>
                      )
                    }
                    style={{ width: 230 }}
                    round
                    id="text-input"
                    placeholder="Search"
                    leftIcon="search"
                    value={this.state.searchTerm}
                    onChange={(e: any) => this.setState({ searchTerm: e.target.value })}
                  />
                  <Button
                    intent="primary"
                    key="1"
                    onClick={() => this.showCreateForm()}
                    style={{ minWidth: 120 }}
                  >
                    New Schema
                  </Button>
                </>
              }
              icon={
                <ModuleEntityIcon
                  moduleName="SchemaModule"
                  entityName="Schema"
                  overrideIcon="diagram-3-fill"
                  overrideIconColor="#1777ff"
                  iconContainerStyle={{ padding: '7px 10px' }}
                />
              }
            />
          </Col>
        </Row>

        <Row style={{ background: 'white', padding: '1px 15px 0 15px' }}>
          <Col span={24}>{this.renderSchemaList()}</Col>
        </Row>
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  listSchemas: (params: any) => dispatch(listSchemasRequest()),
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  createSchema: (params: CreateSchema) => dispatch(createSchemaRequest(params)),
});

export default connect(mapState, mapDispatch)(SchemaListView);
