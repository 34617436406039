import { OrganizationUserGroupEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/group/organization.user.group.entity';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Row, Spin, Tree } from 'antd';
import React, { useEffect, useState, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { getGroupsDataRequest } from '../../../../identityGroups/store/actions';
import { IdentityGroupsReducer } from '../../../../identityGroups/store/reducer';
import {
  bulkUpdateRecordsRequest,
  IBulkUpdateRecords,
  ISearchRecords,
  searchRecordsRequest,
} from '../../../store/actions';
import { IRecordReducer } from '../../../store/reducer';
import { TableReducer } from '../../DynamicTable/store/reducer';
import { httpGet } from '../../../../../shared/http/requests';
import { toggleAssignRecordToGroupModal } from '../../../../userInterface/store/actions';
import { IUserInterfaceReducer } from '../../../../userInterface/store/types';
import { DataNode } from 'antd/es/tree';
import { debugLog } from '../../../../../shared/utilities/developmentHelpers';
import { Dialog, Button, DialogBody, DialogFooter, InputGroup } from '@blueprintjs/core';

export interface Props {
  // Component props
  schema: SchemaEntity | undefined;
  singleRecord?: DbRecordEntityTransform;
  // Redux props
  searchRecords: (params: ISearchRecords) => {};
  bulkUpdateRecords: (params: IBulkUpdateRecords, cb: any) => {};
  alertMessage: (params: { body: string; type: string }) => {};
  getGroupsList: () => {};
  toggleModal: () => void;
  userReducer: any;
  recordReducer: IRecordReducer;
  recordTableReducer: TableReducer;
  identityGroupsReducer: IdentityGroupsReducer;
  userInterfaceReducer: IUserInterfaceReducer;
}

const AssignGroupsToRecords: FunctionComponent<Props> = (props: Props) => {
  const { singleRecord, recordTableReducer, identityGroupsReducer, userInterfaceReducer } = props;

  // Override State
  const [selectedOverrideGroups, setSelectedOverrideGroups] = useState<any[]>([]);
  const [overrideTreeList, setOverrideTreeList] = useState<any[]>([]);
  const [loadedOverrideKeys, setLoadedOverrideKeys] = useState<any[]>([]);
  const [checkedOverrideKeys, setCheckedOverrideKeys] = useState<React.Key[]>([]);
  const [overrideSearchValue, setOverrideSearchValue] = useState<string>('');
  const [loadedAddKeys, setLoadedAddKeys] = useState<any[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  // Remove State

  const [loadedRemoveKeys, setLoadedRemoveKeys] = useState<any[]>([]);

  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('Override');

  const closeModal = () => {
    const { toggleModal } = props;

    // Reset Override Groups
    setSelectedOverrideGroups([]);
    setOverrideTreeList([]);
    setLoadedOverrideKeys([]);
    setCheckedOverrideKeys([]);

    setIsLoadingGroups(false);
    setIsRequesting(false);
    toggleModal();
  };

  // Load groups on modal open
  useEffect(() => {
    if (userInterfaceReducer.assignRecordToGroupModalVisible && !selectedOverrideGroups.length) {
      loadGroups();
    }
  }, [userInterfaceReducer.assignRecordToGroupModalVisible]);

  const filterDataList = (list: any[], searchTerm: string) => {
    let dataList: any[] = [];
    const generateList = (data: any[]) => {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        if (searchTerm.length && node.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
          dataList.push({ key: node.key, title: node.title, id: node.id, children: [] });
        }
        if (node.children) {
          generateList(node.children);
        }
      }
    };
    generateList(list);

    // Remove items with same ids in the filtered group array
    dataList = dataList.filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id),
    );

    return dataList;
  };

  const overrideFilteredList = filterDataList(overrideTreeList, overrideSearchValue);

  // Load initial group hierarchy, this is the initial Tree list
  const loadGroups = async () => {
    setIsLoadingGroups(true);

    await httpGet('IdentityModule/v1.0/rbac/groups/hierarchy-list')
      .then((res: any) => {
        setIsLoadingGroups(false);
        setOverrideTreeList(setInitialTreeList(res.data?.data, 'Override'));

        // If single record, set remove tree list with existing groups from the record
        if (singleRecord && singleRecord.groups?.length! > 0) {
          setSelectedOverrideGroups(singleRecord?.groups!.map((elem: any) => elem.id));
        }
      })
      .catch((err: any) => {
        setIsLoadingGroups(false);
        debugLog('Hierarchy List Error', err);
      });
  };

  const doesRecordHaveGroup = (groupId: string) => {
    if (singleRecord) {
      const record: any = singleRecord;
      return !!record?.groups?.find((recordGroup: any) => recordGroup.id === groupId);
    } else return false;
  };

  // Get groups as tree list compatible with ant design tree component
  const setInitialTreeList = (groups: OrganizationUserGroupEntity[], process: string) => {
    let treeList: any[] = [];
    let selectedKeys: string[] = [];
    let expandedKeys: string[] = [];

    groups.map((elem: any, i: number) => {
      // If group is already preselected, add it to the selected groups
      if (doesRecordHaveGroup(elem.id)) {
        selectedKeys.push(String(i));
      }

      treeList.push({
        title: elem.name || 'No name',
        id: elem.id,
        key: String(i),
        children: elem.subGroups?.map((subElem: OrganizationUserGroupEntity, j: number) => {
          // If group is already preselected, add it to the selected groups
          const key = i + '-' + j;

          if (doesRecordHaveGroup(subElem.id) && selectedKeys.indexOf(key) === -1) {
            selectedKeys.push(key);

            if (expandedKeys.indexOf(String(i)) === -1) {
              expandedKeys.push(String(i));
            }
          }
          return {
            key: key,
            title: subElem.name || 'No name',
            id: subElem.id,
            disabled: process === 'Add' && doesRecordHaveGroup(subElem.id),
          };
        }),
      });

      setCheckedOverrideKeys({ checked: selectedKeys } as any);
    });

    setExpandedKeys(expandedKeys);

    return treeList;
  };

  // Update tree list with new groups
  const updateTreeData = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] => {
    return list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });
  };

  // Load data asynchronously into the tree component
  const onLoadData = async ({ key, children, id }: any) =>
    new Promise<void>(async (resolve) => {
      if (children) {
        resolve();
        return;
      } else {
        if (activeTab == 'Override') {
          setLoadedOverrideKeys([...loadedOverrideKeys, key]);
        } else if (activeTab == 'Add') {
          setLoadedAddKeys([...loadedAddKeys, key]);
        } else {
          setLoadedRemoveKeys([...loadedRemoveKeys, key]);
        }

        if (id) {
          httpGet(`IdentityModule/v1.0/rbac/groups/hierarchy/${id}`)
            .then((res: any) => {
              if (res.data?.data?.subGroups?.length! > 0) {
                if (activeTab == 'Override') {
                  setOverrideTreeList((origin: any) =>
                    updateTreeData(
                      origin,
                      key,
                      res.data?.data?.subGroups.map((elem: any, i: number) => ({
                        title: elem.name || 'No name',
                        id: elem.id,
                        key: key + '-' + i,
                      })),
                    ),
                  );
                }

                resolve();
              } else {
                resolve();
              }
            })
            .catch((err: any) => {
              resolve();
            });
        }
      }
    });

  const onCheck = (checkedKeysValue: any, info: any) => {
    if (activeTab == 'Override') {
      // If node is selected, remove it from the list
      if (selectedOverrideGroups.includes(info.node.id)) {
        const newGroups = selectedOverrideGroups.filter((elem: any) => elem !== info.node.id);
        setSelectedOverrideGroups(newGroups);
      } else {
        setSelectedOverrideGroups([...selectedOverrideGroups, info.node.id]);
      }
      setCheckedOverrideKeys(checkedKeysValue);
    }
  };

  // Deselect all Groups in the Tree
  const deselectAll = () => {
    setSelectedOverrideGroups([]);
    setCheckedOverrideKeys([]);
  };

  const handleSubmit = async () => {
    const {
      schema,
      recordReducer,
      recordTableReducer,
      bulkUpdateRecords,
      alertMessage,
      singleRecord,
    } = props;

    const update = {
      schemaId: schema?.id,
      groups: selectedOverrideGroups,
    };

    if (schema) {
      setIsRequesting(true);

      const bulkUpdateParams: IBulkUpdateRecords = {
        schema,
        createUpdate: update,
      };

      if (singleRecord) {
        bulkUpdateParams.recordIds = [singleRecord.id];
      } else if (recordTableReducer?.selectedItems?.length > 0) {
        bulkUpdateParams.recordIds = recordTableReducer.selectedItems;
      }
      // bulk update records in current search
      else {
        const searchQuery = recordReducer.searchQuery[schema.id];
        const isNotEmptyQuery =
          !!searchQuery?.terms ||
          !!searchQuery?.boolean?.must?.length ||
          !!searchQuery?.boolean?.must_not?.length ||
          !!searchQuery?.boolean?.should?.length ||
          !!searchQuery?.boolean?.filter?.length;
        if (isNotEmptyQuery) {
          bulkUpdateParams.searchQuery = {
            schemas: schema.id,
            fields: searchQuery?.fields,
            terms: searchQuery?.terms,
            boolean: searchQuery?.boolean,
            sort: searchQuery?.sort,
            pageable: {
              page: 1,
            },
          };
        }
      }

      if (bulkUpdateParams?.searchQuery || bulkUpdateParams?.recordIds) {
        bulkUpdateRecords(bulkUpdateParams, (resp: any) => {
          if (resp) {
            closeModal();
            if (resp.results?.sentToEmail) {
              alertMessage({
                body: 'processing groups assignment, results will be sent to the email',
                type: 'success',
              });
            } else {
              alertMessage({
                body: 'groups assignment successful',
                type: 'success',
              });
            }
            refreshSearch();
          }
        });
      } else {
        closeModal();
        alertMessage({
          body: 'searchQuery is not defined, bulk update is not allowed',
          type: 'error',
        });
      }
    }
  };

  const refreshSearch = () => {
    const { schema, recordReducer, searchRecords } = props;

    if (schema && !recordReducer.isSearching) {
      const searchQuery = recordReducer.searchQuery[schema.id];

      searchRecords({
        schema: schema,
        searchQuery: {
          schemas: schema.id,
          fields: searchQuery?.fields,
          terms: searchQuery?.terms,
          boolean: searchQuery?.boolean,
          sort: searchQuery?.sort,
          pageable: {
            page: 1,
          },
        },
      });
    }
  };

  const isDisabled = () => {
    const existingGroupIds: string[] = singleRecord?.groups?.map((elem: any) => elem.id) || [];

    // Loaders...
    if (isLoadingGroups || isRequesting) {
      return true;
    }
    // check if selectedOverrideGroups is exactly the same as existingGroupIds
    else if (
      singleRecord &&
      selectedOverrideGroups.length === existingGroupIds.length &&
      selectedOverrideGroups.every((element: string) => existingGroupIds.includes(element))
    ) {
      return true;
    }
    // There are no records on group, and user selected nothing
    else if (!singleRecord && selectedOverrideGroups.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Dialog
      title={
        singleRecord || recordTableReducer?.selectedItems.length === 1
          ? 'Manage Groups on 1 Record'
          : `Manage Groups on ${recordTableReducer?.selectedItems.length} Records`
      }
      isOpen={userInterfaceReducer.assignRecordToGroupModalVisible}
      onClose={closeModal}
      canOutsideClickClose={true}
    >
      <DialogBody>
        <Spin spinning={identityGroupsReducer.isRequesting}>
          {/* Override */}
          <div style={{ display: activeTab === 'Override' ? 'block' : 'none' }}>
            <Row justify="space-between">
              <Col span={18}>
                <InputGroup
                  disabled={isLoadingGroups}
                  placeholder="Search Groups"
                  leftIcon="search"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setOverrideSearchValue(e.target.value);
                  }}
                />
              </Col>
              <Col span={5}>
                <Button
                  text="Deselect All"
                  disabled={selectedOverrideGroups.length === 0}
                  onClick={deselectAll}
                />
              </Col>
            </Row>
            <Row
              style={{
                height: 350,
                marginTop: 10,
                backgroundColor: 'white',
                padding: '5px 1px',
                overflowY: 'scroll',
                border: '1px solid #d9d9d9',
              }}
            >
              <Col span={24} style={{ opacity: isLoadingGroups ? 0.3 : 1 }}>
                <Tree
                  expandedKeys={expandedKeys}
                  onExpand={(expandedKeys: any) => setExpandedKeys(expandedKeys)}
                  checkStrictly
                  disabled={isLoadingGroups || isRequesting}
                  multiple
                  checkable
                  selectable={false}
                  treeData={
                    overrideSearchValue.length > 0 ? overrideFilteredList : overrideTreeList
                  }
                  loadData={onLoadData}
                  checkedKeys={checkedOverrideKeys}
                  onCheck={onCheck}
                />
              </Col>
            </Row>
          </div>
        </Spin>
      </DialogBody>
      <DialogFooter
        actions={[
          <Button disabled={isRequesting} text="Close" onClick={closeModal} />,
          <Button
            text="OK"
            intent="primary"
            loading={isRequesting}
            onClick={handleSubmit}
            disabled={isDisabled()}
          />,
        ]}
      >
        {selectedOverrideGroups.length > 0 && <span>{selectedOverrideGroups.length} selected</span>}
      </DialogFooter>
    </Dialog>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  recordTableReducer: state.recordTableReducer,
  identityGroupsReducer: state.identityGroupsReducer,
  userInterfaceReducer: state.userInterfaceReducer,
});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords) => dispatch(searchRecordsRequest(params)),
  bulkUpdateRecords: (params: IBulkUpdateRecords, cb: any) =>
    dispatch(bulkUpdateRecordsRequest(params, cb)),
  getGroupsList: () => dispatch(getGroupsDataRequest()),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  toggleModal: () => dispatch(toggleAssignRecordToGroupModal()),
});

export default connect(mapState, mapDispatch)(AssignGroupsToRecords);
