export const UPDATE_SCHEMA_REDUCER_STATE = 'UPDATE_SCHEMA_REDUCER_STATE';

export const LIST_SCHEMAS_REQUEST = 'LIST_SCHEMAS_REQUEST';
export const LIST_SCHEMAS_SUCCESS = 'LIST_SCHEMAS_SUCCESS';
export const LIST_SCHEMAS_ERROR = 'LIST_SCHEMAS_ERROR';

export const GET_SCHEMAS_BY_MODULE_REQUEST = 'GET_SCHEMAS_BY_MODULE_REQUEST';
export const GET_SCHEMAS_BY_MODULE_SUCCESS = 'GET_SCHEMAS_BY_MODULE_SUCCESS';
export const GET_SCHEMAS_BY_MODULE_ERROR = 'GET_SCHEMAS_BY_MODULE_ERROR';

export const GET_SCHEMA_BY_MODULE_AND_ENTITY_REQUEST = 'GET_SCHEMA_BY_MODULE_AND_ENTITY_REQUEST';
export const GET_SCHEMA_BY_MODULE_AND_ENTITY_SUCCESS = 'GET_SCHEMA_BY_MODULE_AND_ENTITY_SUCCESS';
export const GET_SCHEMA_BY_MODULE_AND_ENTITY_ERROR = 'GET_SCHEMA_BY_MODULE_AND_ENTITY_ERROR';

export const GET_SCHEMA_BY_ID_REQUEST = 'GET_SCHEMA_BY_ID_REQUEST';
export const GET_SCHEMA_BY_ID_SUCCESS = 'GET_SCHEMA_BY_ID_SUCCESS';
export const GET_SCHEMA_BY_ID_ERROR = 'GET_SCHEMA_BY_ID_ERROR';

export const CREATE_SCHEMA_REQUEST = 'CREATE_SCHEMA_REQUEST';
export const CREATE_SCHEMA_SUCCESS = 'CREATE_SCHEMA_SUCCESS';
export const CREATE_SCHEMA_ERROR = 'CREATE_SCHEMA_ERROR';

export const UPDATE_SCHEMA_BY_ID_REQUEST = 'UPDATE_SCHEMA_BY_ID_REQUEST';
export const UPDATE_SCHEMA_BY_ID_SUCCESS = 'UPDATE_SCHEMA_BY_ID_SUCCESS';
export const UPDATE_SCHEMA_BY_ID_ERROR = 'UPDATE_SCHEMA_BY_ID_ERROR';

export const DELETE_SCHEMA_BY_ID_REQUEST = 'DELETE_SCHEMA_BY_ID_REQUEST';
export const DELETE_SCHEMA_BY_ID_SUCCESS = 'DELETE_SCHEMA_BY_ID_SUCCESS';
export const DELETE_SCHEMA_BY_ID_ERROR = 'DELETE_SCHEMA_BY_ID_ERROR';

export const BATCH_CREATE_SCHEMA_PERMISSIONS_REQUEST = 'BATCH_CREATE_SCHEMA_PERMISSIONS_REQUEST';
export const BATCH_CREATE_SCHEMA_PERMISSIONS_SUCCESS = 'BATCH_CREATE_SCHEMA_PERMISSIONS_SUCCESS';
export const BATCH_CREATE_SCHEMA_PERMISSIONS_ERROR = 'BATCH_CREATE_SCHEMA_PERMISSIONS_ERROR';

export const BATCH_DELETE_SCHEMA_PERMISSIONS_REQUEST = 'BATCH_DELETE_SCHEMA_PERMISSIONS_REQUEST';
export const BATCH_DELETE_SCHEMA_PERMISSIONS_SUCCESS = 'BATCH_DELETE_SCHEMA_PERMISSIONS_SUCCESS';
export const BATCH_DELETE_SCHEMA_PERMISSIONS_ERROR = 'BATCH_DELETE_SCHEMA_PERMISSIONS_ERROR';

export const CREATE_SCHEMA_TYPE_REQUEST = 'CREATE_SCHEMA_TYPE_REQUEST';
export const CREATE_SCHEMA_TYPE_SUCCESS = 'CREATE_SCHEMA_TYPE_SUCCESS';
export const CREATE_SCHEMA_TYPE_ERROR = 'CREATE_SCHEMA_TYPE_ERROR';

export const DELETE_SCHEMA_TYPE_REQUEST = 'DELETE_SCHEMA_TYPE_REQUEST';
export const DELETE_SCHEMA_TYPE_SUCCESS = 'DELETE_SCHEMA_TYPE_SUCCESS';
export const DELETE_SCHEMA_TYPE_ERROR = 'DELETE_SCHEMA_TYPE_ERROR';

export const CLEAN_SCHEMA_SHORTLIST = 'CLEAN_SCHEMA_SHORTLIST';

export const GET_SCHEMA_ACTION_LIST_REQUEST = 'GET_SCHEMA_ACTION_LIST_REQUEST';
export const GET_SCHEMA_ACTION_LIST_SUCCESS = 'GET_SCHEMA_ACTION_LIST_SUCCESS';
export const GET_SCHEMA_ACTION_LIST_ERROR = 'GET_SCHEMA_ACTION_LIST_ERROR';

export const GET_SINGLE_SCHEMA_ACTION_REQUEST = 'GET_SINGLE_SCHEMA_ACTION_REQUEST';
export const GET_SINGLE_SCHEMA_ACTION_SUCCESS = 'GET_SINGLE_SCHEMA_ACTION_SUCCESS';
export const GET_SINGLE_SCHEMA_ACTION_ERROR = 'GET_SINGLE_SCHEMA_ACTION_ERROR';

export const CREATE_SCHEMA_ACTION_REQUEST = 'CREATE_SCHEMA_ACTION_REQUEST';
export const CREATE_SCHEMA_ACTION_SUCCESS = 'CREATE_SCHEMA_ACTION_SUCCESS';
export const CREATE_SCHEMA_ACTION_ERROR = 'CREATE_SCHEMA_ACTION_ERROR';

export const UPDATE_SCHEMA_ACTION_REQUEST = 'UPDATE_SCHEMA_ACTION_REQUEST';
export const UPDATE_SCHEMA_ACTION_SUCCESS = 'UPDATE_SCHEMA_ACTION_SUCCESS';
export const UPDATE_SCHEMA_ACTION_ERROR = 'UPDATE_SCHEMA_ACTION_ERROR';

export const DELETE_SCHEMA_ACTION_REQUEST = 'DELETE_SCHEMA_ACTION_REQUEST';
export const DELETE_SCHEMA_ACTION_SUCCESS = 'DELETE_SCHEMA_ACTION_SUCCESS';
export const DELETE_SCHEMA_ACTION_ERROR = 'DELETE_SCHEMA_ACTION_ERROR';

export const REFRESH_SCHEMA_SHORTLIST_REQUEST = 'REFRESH_SCHEMA_SHORTLIST_REQUEST';
export const REFRESH_SCHEMA_SHORTLIST_SUCCESS = 'REFRESH_SCHEMA_SHORTLIST_SUCCESS';
